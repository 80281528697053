import { IStatProp } from '.'

export const defaultStats: IStatProp[] = [
  {
    text: 'estudantes conquistando o próximo passo por meio do ISA',
    prefix: '+',
    value: '1500',
  },
  {
    text: 'destinado à formação de profissionais',
    prefix: '+R$',
    suffix: 'milhões',
    value: '50',
  },
  {
    text: 'é a média salarial depois após a formação',
    prefix: '+R$',
    value: '2700',
  },
]
