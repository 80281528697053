import { Container, Description, Icon, Item } from './styles'

const BENEFITS = [
  'Somos pioneiros do modelo no país',
  'Entregamos um fluxo completo: desde a inscrição até o acompanhamento da jornada profissional',
  'Mais de 70% dos alunos empregados em até 3 meses',
]

export const Benefits = () => (
  <Container>
    {BENEFITS.map((benefit, index) => (
      <Item key={index.toString()}>
        <Icon loading="lazy" src="/assets/svg/check-mark.svg" alt="Ilustração de um tique correto." />
        <Description>{benefit}</Description>
      </Item>
    ))}
  </Container>
)
