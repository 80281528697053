import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const SectionsCards = styled.div`
  max-width: 1016px;

  ${theme.breakpoints.up('md')} {
    margin: 24px auto;
  }
`
export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${theme.breakpoints.up('md')} {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    padding-right: 48px;

    > div {
      width: 100%;
      height: 100%;

      &:nth-child(n + 4) {
        transform: translateX(60px);
      }
    }
  }

  ${theme.breakpoints.up('lg')} {
    padding-right: 0px;
    > div {
      width: 100%;

      &:nth-child(n + 4) {
        transform: translateX(90px);
      }
    }
  }
`

export const Icon = styled.img``
