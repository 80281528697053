import {
  StepContainer,
  InfoContainer,
  Info,
  StepTitleWrapper,
  InfoTitle,
  StepTitle,
  SubTitle,
  TextWrapper,
} from './Step.styles'

const Step = () => (
  <StepContainer>
    <StepTitleWrapper>
      <InfoTitle>Estudar com ajuda da Provi é simples:</InfoTitle>
    </StepTitleWrapper>

    <InfoContainer>
      <Info>
        <TextWrapper>
          <StepTitle>1</StepTitle>
          <SubTitle>Escolha o curso, a escola e a forma de pagamento que mais se encaixa no seu objetivo</SubTitle>
        </TextWrapper>
      </Info>
      <Info>
        <TextWrapper>
          <StepTitle>2</StepTitle>
          <SubTitle>
            Preencha as informações necessárias e envie seus documentos. A análise poderá ser realizada em até 24 horas
          </SubTitle>
        </TextWrapper>
      </Info>
      <Info>
        <TextWrapper>
          <StepTitle>3</StepTitle>
          <SubTitle>
            Em alguns casos, você pode precisar indicar uma pessoa para ser avalista. Não se preocupe, vamos te ajudar com o
            processo: é simples e 100% online
          </SubTitle>
        </TextWrapper>
      </Info>
    </InfoContainer>
  </StepContainer>
)

export default Step
