import { ROOT_URL } from '~/constants'
import { ICardTestimonial } from './CardTestimonial/CardTestimonial'

export type ICardTestimonials = Omit<ICardTestimonial, 'backgroundColor' | 'visible'>

export const contentItems: ICardTestimonials[] = [
  {
    text:
      'A Provi está do meu lado desde o início da minha transição de carreira que começou durante a pandemia e participar de seus eventos contribuiu para o fortalecimento da minha autoconfiança nos processos seletivos.',
    name: 'Gabriela Nakasato',
    label: 'Analista de prevenção à fraude na Pagar.me',
    image: `${ROOT_URL?.CLOUD_URL}/images/testimonial_gabriela.webp`,
  },
  {
    text:
      'Conversei com um especialista sobre o cenário de recrutamento de pessoas desenvolvedoras júnior e como se preparar para ele. Imediatamente reformulei meu currículo, apliquei para vagas e obtive retorno. Logo em seguida, veio a contratação como Desenvolvedora.',
    name: 'Marivone Araújo',
    label: 'Analista de software na Cromai',
    image: `${ROOT_URL?.CLOUD_URL}/images/testimonial_marivone.webp`,
  },
  {
    text:
      'Com ajuda da Provi pude realizar um dos meus sonhos: tornar-me programador em TI. Eu havia visto um curso de Full Stack na Kenzie Academy Brasil, justamente o que eu queria. Conheci a parceria entre a Kenzie e a Provi e graças ao apoio das duas empresas, meu sonho é realidade!',
    name: 'Franklin Percicotte',
    label: 'estudante de Full Stack na Kenzie Academy',
    image: `${ROOT_URL?.CLOUD_URL}/images/testimonial_franklin.webp`,
  },
]

export const howItWorksItems: ICardTestimonials[] = [
  {
    text:
      'Conversei com um especialista sobre o cenário de recrutamento de pessoas desenvolvedoras júnior e como se preparar para ele. Imediatamente reformulei meu currículo, apliquei para vagas e obtive retorno. Logo em seguida, veio a contratação como Desenvolvedora.',
    name: 'Marivone Araújo',
    label: 'Analista de software na Cromai',
    image: `${ROOT_URL?.CLOUD_URL}/images/testimonial_marivone.webp`,
  },
  {
    text:
      'Com ajuda da Provi pude realizar um dos meus sonhos: tornar-me programador em TI. Eu havia visto um curso de Full Stack na Kenzie Academy Brasil, justamente o que eu queria. Conheci a parceria entre a Kenzie e a Provi e graças ao apoio das duas empresas, meu sonho é realidade!',
    name: 'Franklin Percicotte',
    label: 'estudante de Full Stack na Kenzie Academy',
    image: `${ROOT_URL?.CLOUD_URL}/images/testimonial_franklin.webp`,
  },
  {
    text:
      'A Provi está do meu lado desde o início da minha transição de carreira que começou durante a pandemia e participar de seus eventos contribuiu para o fortalecimento da minha autoconfiança nos processos seletivos.',
    name: 'Gabriela Nakasato',
    label: 'Analista de prevenção à fraude na Pagar.me',
    image: `${ROOT_URL?.CLOUD_URL}/images/testimonial_gabriela.webp`,
  },
]
