import { FC } from 'react'
import { SectionWrapper, Container, InfoContainer, Number, NumberComplement, Information } from './styles'
import { defaultStats } from './values'

export interface IStatProp {
  text: string
  prefix?: string
  suffix?: string
  value: string
}

interface IStats {
  stats?: IStatProp[]
  isVariant?: boolean
  isBig?: boolean
}

export const Stats: FC<IStats> = ({ stats = defaultStats, isVariant, isBig }) => (
  <SectionWrapper isVariant={isVariant}>
    <Container isVariant={isVariant}>
      {stats.map(({ text, prefix, suffix, value }, index) => (
        <InfoContainer key={index.toString()} isMiddle={(index + 1) % 2 === 0}>
          <Number>
            {prefix && <NumberComplement isBig={isBig}>{prefix}</NumberComplement>}
            {value}
            {suffix && <NumberComplement isBig={isBig}>{suffix}</NumberComplement>}
          </Number>
          <Information>{text}</Information>
        </InfoContainer>
      ))}
    </Container>
  </SectionWrapper>
)
