import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const CardContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  color: #ffffff;
  background-color: ${theme.colors.bluePrimary};
`

export const Icon = styled.img``

export const CardTitle = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;

  ${theme.breakpoints.up('md')} {
    font-size: 25px;
    line-height: 32px;
  }
`

export const Description = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`
