import { CollapseGroup } from '~/components/index'
import { ICollapseSectionGrouped, IGetCategories } from 'types'
import { FAQSectionWrapper, FAQSectionTitle, FAQQuestionListWrapper } from './FAQQuestionsList.style'
import { useWindowSize } from '~/hooks/useWindowSize'

interface ISection {
  anchor: string
  label: string
  slug?: string
}

interface IFAQQuestionsList {
  questions: ICollapseSectionGrouped
  sections: IGetCategories[]
  menuItems: ISection[]
}

const FAQQuestionsList = ({ questions, sections, menuItems }: IFAQQuestionsList) => {
  const { width } = useWindowSize()

  const menuOrder = [menuItems[2], menuItems[4], menuItems[3], menuItems[1], menuItems[0]]

  const desktopList = menuOrder.map((val: ISection) => {
    const currentSection = questions[val.anchor].length ? (
      <FAQSectionWrapper id={val.anchor} key={val.anchor}>
        <FAQSectionTitle>{sections.find((section) => String(val.anchor) === String(section.id))?.name || ''}</FAQSectionTitle>
        <CollapseGroup items={questions[val.anchor]} />
      </FAQSectionWrapper>
    ) : null
    return currentSection
  })

  const mobileList = menuOrder
    .filter((val) => questions[val.anchor].length)
    .map((val: ISection) => {
      const currentSection = {
        title: sections.find((section) => String(val.anchor) === String(section.id))?.name || '',
        featuredTitle: true,
        content: (
          <FAQSectionWrapper id={val.anchor} key={val.anchor}>
            <CollapseGroup items={questions[val.anchor]} />
          </FAQSectionWrapper>
        ),
      }

      return currentSection
    })
  const mobileQuestionList = <CollapseGroup key="outside" items={mobileList} />

  return <FAQQuestionListWrapper>{!!width && width > 750 ? desktopList : mobileQuestionList}</FAQQuestionListWrapper>
}

export default FAQQuestionsList
