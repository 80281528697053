import styled from 'styled-components'
import { theme } from '@provi/provi-components'

interface IImage {
  backgroundImage?: string
  alt?: string
}

export const CardPublicationContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 288px;
  overflow: hidden;

  ${theme.breakpoints.down('md')} {
    max-width: 268px;
  }
`

export const Wrapper = styled.a`
  background-color: ${theme.colors.white100};
  border-radius: 8px;
  width: 288px;
  height: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const ImageContainer = styled.div`
  position: relative;
  height: 150px;
  width: 100%;
`

export const Image = styled.div<IImage>`
  border-radius: 8px 8px 0px 0px;
  background: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : theme.colors.white100)} no-repeat 75%
    15%;
  transform: scale(1);
  width: 100%;
  height: 164px;
`

export const CardTexts = styled.div`
  flex: 1;
  padding-top: 6px;
  position: relative;
  background: white;
  user-select: none;

  display: flex;
  flex-direction: column;
`

export const Tag = styled.strong`
  color: ${theme.colors.bluePrimary};
  padding: 10px 15px 0;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  ${theme.breakpoints.up('md')} {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.015em;
  }
`

export const Title = styled.h3`
  padding: 10px 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
  color: black;
  text-decoration: none;
`
