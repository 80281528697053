import { Container, Img, ContentTestimonial } from './CardTestimonial.styles'
import CardContent from '../CardContent'

export interface ICardTestimonial {
  text: string
  name: string
  label: string
  image: string
  backgroundColor: string
  visible?: boolean
  isVariant?: boolean
  title?: string
}

const CardTestimonial = (props: ICardTestimonial) => {
  const { image, backgroundColor, visible = true, isVariant, ...contentProps } = props

  return (
    <Container backgroundColor={backgroundColor} isVisible={visible}>
      <Img src={image} alt="Imagem de três pessoas diferentes que compartilham seu relato" isVariant={isVariant} />
      <ContentTestimonial isVariant={isVariant}>
        <CardContent {...contentProps} />
      </ContentTestimonial>
    </Container>
  )
}

export default CardTestimonial
