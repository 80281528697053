import { useState } from 'react'
import { ICollapseSection } from 'types'
import CollapseSection from '../CollapseSection'

interface ICollapse {
  items: ICollapseSection[]
  variant?: boolean
}

export const CollapseGroup = ({ items, variant }: ICollapse) => {
  const [activeItemIndex, setActiveItemIndex] = useState<number>(-1)

  return (
    <>
      {items &&
        !!items.length &&
        items.map((itemProps, index) => (
          <CollapseSection
            {...itemProps}
            onChange={() => {
              setActiveItemIndex(index)
            }}
            active={activeItemIndex === index}
            variant={variant}
            isLast={items.length - 1 === index}
            key={itemProps.title}
          />
        ))}
    </>
  )
}
