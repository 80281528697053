import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IIndicator {
  isActive?: boolean
}

export const CarouselStoryContainer = styled.section`
  background-color: ${theme.colors.bluePrimary};
  padding: 42px 24px 24px;
  position: relative;

  .carousel-container ul > li {
    border-top: 2px solid ${theme.colors.white100};
  }

  @media (max-width: 875px) {
    padding-bottom: 94px;
    height: 100%;

    .carousel-container ul > li {
      border-top: none;
    }
  }
`

export const CarouselContent = styled.div`
  max-width: 1016px;
  margin: 0 auto;
  position: relative;

  .carousel-container {
    min-height: 370px;
    align-items: flex-start;
  }
`

export const StoryContent = styled.div`
  height: 224px;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 875px) {
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    gap: 19px;

    :nth-child(2) {
      border-top: none;
    }
  }
`

export const StorySection = styled.div`
  width: 496px;

  :nth-child(1n + 2) {
    max-width: 496px;
  }

  @media (max-width: 875px) {
    width: 100%;

    ul > li {
      background-color: red;
    }

    :nth-child(1n + 2) {
      max-width: 492px;
      margin: 0 auto;
    }
  }
`

export const StoryText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
  color: ${theme.colors.white100};
  user-select: none;

  :nth-child(2) {
    margin-top: 10px;
  }

  &.story-text {
    margin-top: 24px;
  }

  @media (max-width: 875px) {
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.005em;

    &.story-text {
      margin-top: 0;
    }
  }
`

export const BigNumber = styled.h2`
  font-weight: 500;
  font-size: 96px;
  line-height: 128px;
  letter-spacing: -0.0025em;
  color: ${theme.colors.white100};

  @media (max-width: 875px) {
    font-size: 48.83px;
    line-height: 60px;
    border-bottom: 2px solid ${theme.colors.white100};
  }
`

export const IndicatorBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 186px;
  right: 337px;

  button {
    border: none;
    background-color: transparent;
  }

  @media (max-width: 875px) {
    width: calc(100% - 25px);
    top: unset;
    bottom: -68px;
    right: auto;
  }
`

export const IndicatorContent = styled.div`
  margin: 0 25px;
  display: flex;
`

export const ControlButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;

  &:first-child img {
    transform: scaleX(-1);
  }

  img {
    pointer-events: none;

    path {
      stroke: black;
    }
  }
`

export const Indicator = styled.div<IIndicator>`
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${theme.colors.bluePrimaryDark};

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${theme.colors.white100};

      a {
        visibility: visible;
      }
    `}
`
