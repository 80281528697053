import styled from 'styled-components'
import { ButtonV3 as Button, theme } from '@provi/provi-components'

interface ICollapseWrapper {
  variant?: boolean
}

export const CollapseWrapper = styled.section<ICollapseWrapper>`
  display: flex;
  justify-content: center;
  background: ${({ variant }) => (variant ? theme.colors.bluePrimaryLight : 'unset')};

  ${theme.breakpoints.up('md')} {
    min-height: 520px;
    padding-bottom: 76px;
  }
`

export const CollapseContentColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const CollapseContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1064px;
  padding: 34px 24px 40px;

  ${theme.breakpoints.up('md')} {
    padding: 82px 24px 8px;
    flex-direction: row;
  }
`

export const FAQContent = styled.div`
  width: 100%;
  max-width: 600px;

  p {
    margin: 24px 0;
  }

  ol,
  ul {
    padding: 0 24px;
  }

  ${theme.breakpoints.up('md')} {
    margin-left: 32px;
  }
`

export const TitleFaq = styled.h2`
  font-weight: 500;
  font-size: 22.81px;
  line-height: 36px;
  margin-bottom: 44px;

  ${theme.breakpoints.up('md')} {
    font-size: 31.25px;
    line-height: 40px;
  }
`

export const Span = styled.div`
  width: 288px;
  max-width: 100%;
  margin: 40px 0 24px;

  ${theme.breakpoints.up('md')} {
    margin: 51px 0 23px;
  }
`

export { Button }
