import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IContainer {
  isActive: boolean
}

type ISvg = IContainer

const activeCss = css`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
  color: #000000;

  a {
    color: ${theme.colors.bluePrimary};
    visibility: visible;
    position: relative;
  }

  svg {
    fill: ${theme.colors.bluePrimary};
  }
`

export const ArticleCardContainer = styled.div<IContainer>`
  background-color: ${theme.colors.white100};
  margin: 0 auto;
  max-width: 392px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  border-radius: 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
  color: ${theme.colors.greyPrimary};

  a {
    color: ${theme.colors.greyPrimary};
  }

  svg {
    fill: ${theme.colors.greyPrimary};
  }

  ${({ isActive }) => isActive && activeCss};

  ${theme.breakpoints.down('md')} {
    ${activeCss}
  }
`

export const CardWrapper = styled.div``

export const ArticleCardTitle = styled.h3`
  font-weight: bold;
  font-size: 13.2px;
  line-height: 20px;

  margin: 9px 0;
  text-transform: uppercase;

  ${theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const ArticleCardText = styled.p`
  font-weight: 500;
  font-size: 15.84px;
  line-height: 24px;

  ${theme.breakpoints.up('md')} {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
`

export const Link = styled.a`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin: 12px 0 0;
  width: 120px;
  height: 24px;

  visibility: visible;
  position: relative;

  ${theme.breakpoints.up('md')} {
    visibility: hidden;
    position: absolute;
  }
`

export const Icon = styled.img`
  margin: 0px 8px;
`

export const Svg = styled.img<ISvg>`
  max-width: 176px;
  width: 100%;
  object-fit: contain;
  opacity: 0.6;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
    `}

  ${theme.breakpoints.down('md')} {
    opacity: 1;
  }
`
