import { FormContainer, FormSection, FormInformation, FormTitle, FormDescription } from './styles'
import { Inputs } from './Inputs'
import { IFormWithMessage } from 'types'

const FormWithMessage = ({ information, setInformation, isVariant = false }: IFormWithMessage) => (
  <FormContainer>
    <FormSection>
      <FormInformation>
        <FormTitle>Não encontrou o que procurava?</FormTitle>
        <FormDescription>
          {isVariant
            ? 'Envie sua pergunta pra gente.'
            : 'Escreva uma mensagem pra gente. Vamos entender em conjunto como podemos te ajudar.'}
        </FormDescription>
      </FormInformation>
      <Inputs information={information} setInformation={setInformation} isVariant={isVariant} />
    </FormSection>
  </FormContainer>
)

export default FormWithMessage
