import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const EventsContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  padding: 24px;
  width: 100%;
  max-width: 1064px;
  margin: 0 auto;

  @media (max-width: 870px) {
    flex-direction: column;
    width: 100%;
    max-width: 1064px;
  }

  @media (min-width: 670px) {
    justify-content: space-between;
    padding: 32px 24px 66px;
  }
`

export const Title = styled.h1`
  max-width: 288px;
  font-weight: 500;
  color: ${theme.colors.bluePrimary};
  font-size: 27.37px;
  line-height: 40px;

  @media (max-width: 870px) {
    min-width: 100%;
    margin-bottom: 24px;
  }

  @media (min-width: 670px) {
    font-size: 39.06px;
    line-height: 48px;
  }
`

export const CardEventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;

  @media (min-width: 670px) {
    margin: 0 auto;
    flex-direction: row;
    border-top: 16px;
  }

  @media (min-width: 960px) {
    margin: 0;
  }
`

export const NextEventContainer = styled.div`
  display: flex;
  justify-content: center;
`
