import { FAQContainer, FAQHeaderContent, FAQTitle, FAQBreadcrumbWrapper, A, CurrentCrumb } from './FAQHeader.style'
import theme from '~/styles/theme'
import { Breadcrumbs } from '@provi/provi-components'
import { IBreadcrumb } from 'types'
import NextLink from 'next/link'

const breadcrumbsProps: IBreadcrumb = {
  levels: [
    <NextLink href="/" passHref legacyBehavior>
      <A>Início</A>
    </NextLink>,
    'A Provi',
    <CurrentCrumb>Perguntas frequentes</CurrentCrumb>,
  ],
  currentColor: 'white',
  color: 'white',
}

const FAQHeader = () => (
  <FAQContainer backgroundColor={theme.provi.colors.bluePrimary}>
    <FAQHeaderContent>
      <FAQBreadcrumbWrapper>
        {breadcrumbsProps && (
          <Breadcrumbs
            levels={breadcrumbsProps.levels}
            color={breadcrumbsProps?.color || '#000000'}
            currentColor={breadcrumbsProps?.currentColor || theme.provi.colors.bluePrimaryDark}
          />
        )}
      </FAQBreadcrumbWrapper>
      <FAQTitle>Perguntas frequentes</FAQTitle>
    </FAQHeaderContent>
  </FAQContainer>
)

export default FAQHeader
