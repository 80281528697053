import { ButtonV3 as Button } from '@provi/provi-components'
import { NextEventContainer, NextEventContent, Img, Title, Text } from './CardNextEvent.styles'
import { ArrowUp } from '~/assets/svg'
import { INextEvent } from 'types'

const CardNextEvent = ({ image, title, text, link }: INextEvent) => (
  <NextEventContainer>
    <Img src={image} alt="Fotografia de uma mulher negra jovem de cabelo curto e óculos de grau usando o notebook." />

    <NextEventContent>
      <Title>{title}</Title>

      <Text>{text}</Text>

      <Button
        text="Inscreva-se"
        buttonProps={{
          endIcon: <ArrowUp />,
          href: link,
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
      />
    </NextEventContent>
  </NextEventContainer>
)

export default CardNextEvent
