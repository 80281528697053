import { CardContainer, CardTitle, CardText, CardName } from './CardContent.style'

export interface ICardContent {
  text: string
  name: string
  label: string
  title?: string
}

const CardContent = ({ text, name, label, title }: ICardContent) => (
  <CardContainer className="card-content">
    <CardTitle>{title || 'Quem indica'}</CardTitle>

    <CardText>“{text}”</CardText>

    <CardName>
      {name}, <br /> {label}
    </CardName>
  </CardContainer>
)

export default CardContent
