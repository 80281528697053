import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-gap: 20px;

  padding-top: 8px;
`

export const Item = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: 12px;
`

export const Icon = styled.img``

export const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.005em;
`
