import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding-top: 98px;
  max-width: 600px;
  width: 100%;
  grid-column: 8 / span 6;

  ${theme.breakpoints.down('md')} {
    padding-right: 0;
  }
`

export const Thumbnail = styled.div`
  text-align: center;
  height: 334px;
  max-width: 600px;
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%), no-repeat;
  background-size: cover;
  background-position-x: center;
  cursor: pointer;

  img {
    margin-top: 25%;
  }

  ${theme.breakpoints.down('md')} {
    margin: 0 auto;
    height: calc((100vw - 48px) / 1.73);
    width: 100%;
  }
`

export const IFrameVideo = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 16px;
`

export const Description = styled.div`
  padding: 23.27px 0 20px 0;
  font-weight: 500;
  font-size: 13.2px;
  line-height: 20px;
  letter-spacing: 0.005em;

  width: 100%;

  ${theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0075em;

    padding: 15px 0 12px 0;
    max-width: 288px;
  }
`

export const Link = styled.a`
  color: #ffffff;
  text-decoration: none;

  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  transition: color 0.1s ease;

  span {
    margin-right: 11px;
  }

  path {
    stroke: white;
  }

  :hover {
    color: ${theme.colors.bluePrimaryLight};

    path {
      stroke: ${theme.colors.bluePrimaryLight};
    }
  }

  :active,
  :focus,
  :focus-within {
    color: ${theme.colors.blueSecundaryDark};

    path {
      stroke: ${theme.colors.blueSecundaryDark};
    }
  }

  ${theme.breakpoints.down('md')} {
    font-weight: 600;
    font-size: 13.2px;
    line-height: 20px;

    span {
      margin-right: 7px;
    }
  }
`
