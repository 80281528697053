import { EventsContainer, Content, CardEventWrapper, Title, NextEventContainer } from './Events.styles'

import CardBeforeEvent from './CardBeforeEvent/CardBeforeEvent'
import { IEventsData, INextEvent } from 'types'
import CardNextEvent from './CardNextEvent/CardNextEvent'

interface IEventsContainer {
  nextEvent: INextEvent
  beforeEvent: IEventsData[]
}

const Events = ({ nextEvent, beforeEvent }: IEventsContainer) => (
  <EventsContainer>
    <NextEventContainer>
      <CardNextEvent title={nextEvent.title} text={nextEvent.text} image={nextEvent.image} link={nextEvent.link} />
    </NextEventContainer>

    <Content>
      <Title>Últimos eventos</Title>

      <CardEventWrapper>
        {beforeEvent.map((beforeEventItem: IEventsData, index: number) => (
          <CardBeforeEvent
            key={index.toString()}
            title={beforeEventItem.title.rendered}
            text={beforeEventItem.yoast_head_json.og_description}
            image={beforeEventItem.yoast_head_json.og_image[0].url}
            link={beforeEventItem.link}
          />
        ))}
      </CardEventWrapper>
    </Content>
  </EventsContainer>
)

export default Events
