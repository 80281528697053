import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const StepContainer = styled.section`
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  padding: 70px 24px 24px;
  margin: 0 auto;

  @media (max-width: 950px) {
    padding-bottom: 150px;
  }

  @media (max-width: 650px) {
    max-height: 730px;
    padding: 38px 24px 24px;
  }
`

export const StepTitleWrapper = styled.div`
  max-width: 1068px;
  width: 100%;
  margin: 0 auto;
`

export const InfoTitle = styled.h2`
  max-width: 496px;
  font-weight: 500;
  font-size: 39.06px;
  line-height: 48px;
  color: ${theme.colors.bluePrimary};

  @media (max-width: 650px) {
    font-size: 27.37px;
    line-height: 40px;
  }
`

export const InfoContainer = styled.section`
  width: 100%;
  display: flex;

  @media (max-width: 950px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 650px) {
    padding-left: 0;
    height: 100%;
    width: 100%;
  }

  @media (min-width: 950px) {
    min-height: 500px;
    justify-content: space-between;
  }
`

export const Info = styled.div`
  background-image: url('/assets/svg/CircleStep.svg');
  background-repeat: no-repeat;
  background-size: 184px;
  width: 388px;
  height: 241px;

  :nth-child(1) {
    margin-top: 50px;
  }

  :nth-child(2) {
    margin-top: 98px;
  }

  @media (max-width: 950px) {
    max-width: 300px;

    :nth-child(2) {
      margin-top: 0;
      margin-left: 50%;
    }
  }

  @media (max-width: 650px) {
    background-size: 66px;
    height: 100%;
    max-height: 176px;
    min-width: 100%;
    max-width: 230px;

    :nth-child(1) {
      margin-top: 25px;
    }

    :nth-child(2),
    :nth-child(3) {
      margin-top: 32px;
      margin-left: 0;
    }
  }
`

export const TextWrapper = styled.div`
  margin: 90px 0 104px 82px;

  @media (max-width: 650px) {
    display: flex;
    margin: 18px 0 0 35px;
  }
`

export const StepTitle = styled.p`
  font-weight: 600;
  font-size: 128px;
  line-height: 128px;

  @media (max-width: 650px) {
    font-size: 60px;
    line-height: 64px;
  }
`

export const SubTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  @media (max-width: 650px) {
    width: 100%;
    font-size: 15.84px;
    line-height: 24px;
    margin-left: 16px;
  }
`
