import { SummitContainer, SummitContent } from './styles'
import { IIsaSummit } from '~/hooks/Isa'
import Infos from './Infos'
import Video from './Video'

export interface IInfo {
  info: IIsaSummit
}

export const IsaSummit = ({ info }: IInfo) => (
  <SummitContainer>
    <SummitContent>
      <Infos info={info} />
      <Video />
    </SummitContent>
  </SummitContainer>
)
