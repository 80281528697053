import { Container, Description, IFrameVideo, Link, Thumbnail } from './styles'
import { ArrowUp } from '~/assets/svg'

const Video = () => (
  <Container>
    <Thumbnail>
      <IFrameVideo
        src="https://www.youtube.com/embed/TyNM_A_IUTc"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Thumbnail>
    <Description>Acesse aos vídeos completos e confira o que rolou durante o evento.</Description>
    <Link href="https://www.youtube.com/channel/UC0wPjnokNj847ndDPZaDBgQ" target="_blank" rel="noopener noreferrer">
      <>
        <span>Assistir</span>
        <ArrowUp />
      </>
    </Link>
  </Container>
)

export default Video
