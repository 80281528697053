import styled from 'styled-components'

interface ICardProps {
  titleColor?: string
  backgroundColor?: string
  padding?: string
}

export const CardWrapper = styled.div<ICardProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-bottom: 40px;
  max-width: 1016px;
  height: 100%;
  display: flex;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    padding: ${({ padding }) => padding};
    border-radius: 8px;
    flex-direction: column;
    min-height: 263px;
    width: 290px;
  }
`

export const CardTitle = styled.h3<ICardProps>`
  font-weight: 500;
  font-size: 19.1px;
  line-height: 28px;
  letter-spacing: 0.0025em;
  max-width: 256px;
  color: ${({ titleColor }) => titleColor};

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    line-height: 32px;
    font-size: 25px;
  }
`

export const Svg = styled.div<ICardProps>`
  svg {
    path {
      stroke: ${({ titleColor }) => titleColor};
    }
  }
`
export const Section = styled.div`
  margin-left: 34px;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    margin-left: 0;
  }

  p {
    font-weight: 500;
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.005em;
    margin-top: 15px;

    ${({ theme }) => theme.provi.breakpoints.up('md')} {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0075em;
    }
  }
`
