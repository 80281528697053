import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ActionContainer = styled.section`
  width: 100%;
  background: ${theme.colors.bluePrimaryLight};
  display: flex;
  justify-content: center;

  @media (max-width: 690px) {
    justify-content: center;
  }
`

export const Content = styled.div`
  max-width: 1064px;
  display: flex;
  justify-content: space-between;
  padding: 79px 0;
  gap: 128px;

  @media (max-width: 910px) {
    padding: 22px 0 41px;
    flex-direction: column;
    gap: 0;
  }

  @media (max-width: 1120px) {
    gap: 50px;
  }
`

export const SectionWrapper = styled.div`
  :nth-child(2) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 910px) {
    margin: 31px auto 0;

    :nth-child(2) {
      display: flex;
    }
  }

  @media (max-width: 690px) {
    margin: 0;

    :nth-child(2) {
      display: flex;
      flex-direction: column;
      margin: 31px 0;
    }
  }
`

export const CardsWrapper = styled.div`
  > div {
    width: 100%;
    max-width: 312px;
    height: 200px;
    background: transparent;
    flex-direction: column;
    padding: 16px 12px 0;
    border-radius: 8px;
    margin-left: 0px;

    > div {
      margin-left: 0px;

      h4 {
        color: ${theme.colors.bluePrimaryDark};
        font-size: 25px;
        line-height: 32px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 6px;
      }
    }

    @media (max-width: 690px) {
      width: 100%;
      max-width: 358px;
      height: 110px;
      flex-direction: row;
      padding: 0 24px;

      img {
        width: 32px;
        height: 32px;
        margin-top: 6px;
      }

      > div {
        h4,
        p {
          margin-left: 16px;
        }

        h4 {
          font-size: 19.1px;
          line-height: 28px;
        }

        p {
          font-size: 13.2px;
          line-height: 20px;
        }
      }
    }

    @media (min-width: 800px) {
      min-height: 200px;
    }
  }

  :nth-child(2) {
    div:nth-child(4) {
      h4 {
        word-break: break-all;
        max-width: 194px;

        @media (max-width: 690px) {
          max-width: 100%;
        }
      }
    }
  }
`

export const Title = styled.h1`
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  max-width: 288px;
  margin: 0 0 0 24px;

  @media (max-width: 910px) {
    margin: 0 24px 0 6.5%;
    max-width: 660px;
  }

  @media (max-width: 690px) {
    font-size: 22.81px;
    line-height: 36px;
    max-width: 312px;
    margin: 0 24px;
  }
`

export const TitleCard = styled.h1`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0 16px 16px;

  @media (max-width: 690px) {
    font-size: 13.2px;
    line-height: 20px;
    margin-bottom: 0;
    margin: 0 24px 18px;
  }
`

export const Icon = styled.img``
