import { ReactNode } from 'react'
import { BlueTitleContainer, BlueTitle, Content } from './styles'

interface IBlueTitleContent {
  title: string
  content: ReactNode | JSX.Element | string
  tagVariant?: boolean
  isVariant?: boolean
}

export const BlueTitleContent = ({ title, content, tagVariant, isVariant = false }: IBlueTitleContent) => (
  <BlueTitleContainer className="blue-title-content">
    <BlueTitle as={tagVariant ? 'h3' : 'h2'}>{title}</BlueTitle>
    <Content isVariant={isVariant}>{content}</Content>
  </BlueTitleContainer>
)
