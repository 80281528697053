import styled from 'styled-components'

export const CardContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 486px;
  height: 100%;

  @media (max-width: 850px) {
    max-width: 456px;
  }
`

export const CardTitle = styled.h2`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.015em;
  text-transform: uppercase;

  color: #00198a;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 26px 0px;

  @media (max-width: 750px) {
    font-size: 13.2px;
    line-height: 20px;
  }
`

export const CardText = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 26px;

  @media (max-width: 750px) {
    font-size: 15.84px;
    line-height: 24px;
  }
`

export const CardName = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.0075em;

  @media (max-width: 750px) {
    font-size: 13.2px;
    line-height: 20px;
  }
`
