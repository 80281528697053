import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Section = styled.section`
  background-color: ${theme.colors.bluePrimaryDark};
`

export const Container = styled.div`
  ${theme.breakpoints.down('md')} {
    padding: 24px 0;
  }

  ${theme.breakpoints.up('md')} {
    position: relative;
    padding: 40px 0 40px calc((100% - 1044px) / 2);
    overflow: hidden;
  }
`

export const NewsSectionTitle = styled.h2`
  color: ${theme.colors.white100};
  margin-bottom: 30px;
  margin-left: 24px;
  font-size: 19.1px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.005em;
  max-width: 288px;

  ${theme.breakpoints.up('md')} {
    left: 90px;
    min-width: 278px;
    font-size: 31.25px;
    line-height: 40px;
    margin-right: 24px;
  }

  @media (max-width: 1070px) {
    left: 24px;
  }
`

export const IndicatorBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 24px 10px;

  ${theme.breakpoints.up('md')} {
    visibility: hidden;
    position: absolute;
  }

  @media (max-width: 3000px) {
    visibility: visible;
    top: 250px;
  }
`

export const IndicatorContent = styled.div`
  margin: 0 24px;
  display: flex;
`

interface IIndicator {
  isActive?: boolean
}

export const Indicator = styled.button<IIndicator>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  background-color: ${({ isActive }) => (isActive ? '#FFFFFF' : theme.colors.bluePrimary)};
  margin: 0 6px;
`

export const CarouselWrapper = styled.div`
  user-select: none;
  position: relative;
  min-height: 320px;

  ul {
    li {
      padding: 0 12px;

      ${theme.breakpoints.down('md')} {
        padding: 0 24px;
      }
    }
  }

  ${theme.breakpoints.up('md')} {
    .react-multi-carousel-list {
      margin-left: 300px;
    }
  }

  @media (min-width: 2000px) {
    min-height: 350px;
  }

  @media (max-width: 1060px) {
    min-height: 350px;
  }
`

export const ControlButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
`
