import Card, { ICardProps } from './Card'
import { SectionsCards, CardsContainer } from './styles'

interface ICardsGroupProps {
  cards: ICardProps[]
  backgroundColor?: string
  titleColor?: string
  padding?: string
}

export const CardsGroup = ({
  cards,
  backgroundColor = '#D4DAF7',
  titleColor = '#2647D7',
  padding = '16px 16px 18px',
}: ICardsGroupProps) => (
  <SectionsCards>
    <CardsContainer>
      {cards.map((item: ICardProps) => (
        <Card
          key={item.title}
          icon={item.icon}
          title={item.title}
          text={item.text}
          titleColor={titleColor}
          backgroundColor={backgroundColor}
          padding={padding}
        />
      ))}
    </CardsContainer>
  </SectionsCards>
)
