import { CardWrapper, CardTitle, Svg, Section } from './styles'

export interface ICardProps {
  icon?: JSX.Element
  title: string
  text: string
  titleColor?: string
  backgroundColor?: string
  padding?: string
}

const Card = ({ icon, title = '', titleColor = 'black', text = '', backgroundColor = 'white', padding }: ICardProps) => (
  <CardWrapper backgroundColor={backgroundColor} padding={padding} className="card-wrapper">
    {icon && <Svg titleColor={titleColor}>{icon}</Svg>}
    <Section>
      <CardTitle titleColor={titleColor} className="card-title">
        {title}
      </CardTitle>
      <p>{text}</p>
    </Section>
  </CardWrapper>
)

export default Card
