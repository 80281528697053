import styled from 'styled-components'

export const InfoContainer = styled.div`
  display: grid;
  grid-column: 3 / span 4;
  max-width: 100%;

  @media (max-width: 1200px) {
    grid-column: 2 / span 4;
  }
`

export const Cards = styled.div`
  max-width: 392px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding-top: 32px;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    grid-gap: 60px 24px;
    padding-top: 71px;
  }
`

export const Card = styled.div``

export const CardValue = styled.div`
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.0025em;

  strong {
    font-weight: 800;
  }

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    font-size: 72px;
  }
`

export const CardDescription = styled.div`
  font-weight: 500;
  font-size: 16 px;
  line-height: 24px;
  letter-spacing: 0.005em;
`

export const InfoTitle = styled.h2`
  font-weight: 500;
  font-size: 27.37px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  margin-bottom: 15px;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    font-size: 31.25px;
    line-height: 40px;
    margin-bottom: 54px;
  }
`

export const Description = styled.p`
  font-weight: 500;
  font-size: 15.84px;
  line-height: 24px;
  letter-spacing: 0.005em;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    max-width: 392px;
    font-size: 20px;
    line-height: 28px;
  }
`
