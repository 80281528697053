import styled from 'styled-components'
import { theme } from '@provi/provi-components'

interface IContent {
  isVariant: boolean
}

export const BlueTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`

export const BlueTitle = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 31.25px;
  line-height: 40px;
  color: ${theme.colors.bluePrimary};
  margin: 50px 0 42px 0;

  ${theme.breakpoints.down('md')} {
    margin: 0 0 20px 0;
    font-size: 22.81px;
    line-height: 36px;
  }
`

export const Content = styled.div<IContent>`
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
  color: ${theme.colors.black};

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    row-gap: 24px;

    li {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      column-gap: 8px;
    }
  }

  ${theme.breakpoints.down('md')} {
    font-size: 15.84px;
    line-height: 24px;
  }

  @media (min-width: 1045px) {
    max-width: ${({ isVariant }: any) => (isVariant ? '100%' : '496px')};
  }
`
