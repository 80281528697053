import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IIsaVideoWrapper {
  isVariant?: boolean
}

interface IIsaText {
  isVariant?: boolean
}

export const IsaVideoWrapper = styled.section<IIsaVideoWrapper>`
  background: ${theme.colors.bluePrimaryDark};
  padding: 41px 24px 61px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 128px;

  ${({ isVariant }) =>
    isVariant &&
    css`
      background: ${theme.colors.white100};
      align-items: center;
    `};

  @media (max-width: 1064px) {
    gap: 24px;
    justify-content: space-between;
  }

  ${theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
    padding: 24px 24px 40px;
  }
`

export const IsaTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 392px;
  margin-left: calc((100vw - 1064px) / 2);

  @media (max-width: 1064px) {
    margin-left: 0;
    max-width: 312px;
  }

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`

export const IsaTitle = styled.h2`
  font-weight: 500;
  font-size: 31.25px;
  line-height: 40px;
  color: ${theme.colors.bluePrimary};
  margin-bottom: 33px;

  ${theme.breakpoints.down('md')} {
    font-size: 22.81px;
    line-height: 36px;
    letter-spacing: 0.005em;
    margin-bottom: 20px;
  }
`

export const IsaText = styled.p<IIsaText>`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
  margin-bottom: 45px;
  margin-top: 24.27px;
  color: ${theme.colors.white100};

  ${({ isVariant }) =>
    isVariant &&
    css`
      color: #000000;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0075em;
      margin-bottom: 18px;
      margin-top: 0;
    `};

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0075em;
    margin-bottom: 22px;

    ${({ isVariant }) =>
      isVariant &&
      css`
        font-size: 15.84px;
        letter-spacing: 0.005em;
        margin-bottom: 34px;
      `};
  }
`

export const ThumbnailImage = styled.img`
  min-width: 496px;
  max-width: 496px;
  max-height: 330.67px;
  max-height: 392px;
  border-radius: 16px;

  ${theme.breakpoints.down('md')} {
    min-width: 100%;
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`

export const Thumbnail = styled.a`
  text-align: center;
  height: 334px;
  max-width: 600px;
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%),
    url('/assets/images/isa-video.webp') no-repeat;
  background-size: cover;
  background-position-x: center;
  cursor: pointer;

  img {
    margin-top: 25%;
  }

  ${theme.breakpoints.down('md')} {
    max-width: 312px;
    height: 173.73px;
  }
`

export const Icon = styled.img`
  margin-left: 7px;
  z-index: 1;
`
export const Iframe = styled.iframe`
  border-radius: 16px;
`
