import { isEmailValid as emailValid } from '~/lib/utils'
import { IInformation } from 'types'

const regex = /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-\s]*$/
export const isNameValid = (information: IInformation) => {
  const firstName = information.name.split(' ')[0]
  const lastName = information.name.split(' ')[1]

  if (regex.test(firstName) === false || regex.test(lastName) === false) {
    return false
  }

  return firstName.length >= 2 && lastName?.length >= 2
}

export const nameHasError = (information: IInformation) => {
  const { name } = information

  const firstName = name.split(' ')[0]
  const lastName = name.split(' ')[1]

  if (name.length) {
    if (regex.test(firstName) === false || regex.test(lastName) === false) {
      return true
    }

    if (firstName.length >= 2 && lastName && lastName.length >= 2) {
      return false
    }

    return true
  }
}

export const isEmailValid = (information: IInformation) => emailValid(information.email)

export const emailHasError = (information: IInformation) => !!information.email.length && !emailValid(information.email)

export const isMessageValid = (information: IInformation) => information.message

export const canKeyboardEvent = (information: IInformation) =>
  isNameValid(information) && isEmailValid(information) && isMessageValid(information)
