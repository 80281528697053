import { useRef, useState } from 'react'
import CardTestimonial from './CardTestimonial'
import Carousel from 'react-multi-carousel'
import { ArrowLeft, ArrowRight } from '~/assets/svg'
import { useWindowSize } from '~/hooks/useWindowSize'
import { IButtonGroup, SkipCallbackOptions } from 'types'
import { contentItems, ICardTestimonials } from './values'

import {
  TestimonialsContainer,
  Content,
  IndicatorBox,
  IndicatorContent,
  ControlButton,
  Indicator,
  DesktopCarousel,
  DesktopCarouselWrapper,
  DotsWrapper,
} from './Testimonials.style'

interface ITestimonials {
  content?: ICardTestimonials[]
  backgroundColor?: string
  isVariant?: boolean
  title?: string
}

const Testimonials = ({ content = contentItems, backgroundColor = '#d4daf7', isVariant, title }: ITestimonials) => {
  const carousel = useRef<Carousel>({} as Carousel)
  const [activeItem, setActiveItem] = useState<number>(0)

  const nextItem = () => {
    const lastItemIndex = content.length - 1
    const resetItemIndex = activeItem === lastItemIndex
    const index = resetItemIndex ? 0 : activeItem + 1
    setActiveItem(index)
  }

  const prevItem = () => {
    const lastItemIndex = content.length - 1
    const resetItemIndex = activeItem === 0
    const index = resetItemIndex ? lastItemIndex : activeItem - 1
    setActiveItem(index)
  }

  const goToDesktopSlide = (slide: number) => {
    setActiveItem(slide)
  }

  const size = useWindowSize()

  const items = content.map((contentItem: ICardTestimonials, index: number) => {
    const isVisible = size.width && size.width < 750 ? true : activeItem === index

    return (
      <CardTestimonial
        title={title}
        key={index.toString()}
        backgroundColor={backgroundColor}
        {...contentItem}
        visible={isVisible}
        isVariant={isVariant}
      />
    )
  })

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  const getAllDots = (currentSlide: number, goToSlide: (slide: number, skipCallbacks?: SkipCallbackOptions) => void) =>
    items.map((_content, index) => {
      const active = currentSlide === index ? 'active' : ''
      return <Indicator key={index.toString()} isActive={active} onClick={() => goToSlide(index)} />
    })

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }: IButtonGroup) => {
    const {
      carouselState: { currentSlide },
    } = rest
    return (
      <IndicatorBox isVariant={isVariant}>
        <ControlButton type="button" onClick={() => previous(1)}>
          <ArrowRight color="#00198A" />
        </ControlButton>
        <IndicatorContent> {getAllDots(currentSlide - 2, goToSlide)} </IndicatorContent>
        <ControlButton type="button" onClick={() => next(1)}>
          <ArrowLeft color="#00198A" />
        </ControlButton>
      </IndicatorBox>
    )
  }

  const getAllDesktopDots = () =>
    items.map((_content, index) => {
      const active = activeItem === index ? 'active' : ''
      return <Indicator key={index.toString()} isActive={active} onClick={() => goToDesktopSlide(index)} />
    })

  const desktopCarouselControl = () => (
    <IndicatorBox isVariant={isVariant}>
      <ControlButton type="button" className="btn" onClick={() => prevItem()}>
        <ArrowRight color="#00198A" />
      </ControlButton>
      <IndicatorContent> {getAllDesktopDots()} </IndicatorContent>
      <ControlButton type="button" className="btn" onClick={() => nextItem()}>
        <ArrowLeft color="#00198A" />
      </ControlButton>
    </IndicatorBox>
  )

  const desktopCarousel = (
    <DesktopCarouselWrapper>
      <DotsWrapper>{desktopCarouselControl()}</DotsWrapper>
      <DesktopCarousel>{items}</DesktopCarousel>
    </DesktopCarouselWrapper>
  )

  const mobileCarousel = (
    <div>
      {!!carousel.current && (
        <Carousel
          ref={carousel}
          swipeable
          draggable
          showDots={false}
          arrows={false}
          responsive={responsive}
          ssr
          infinite
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          customButtonGroup={
            <ButtonGroup
              next={carousel.current.next}
              previous={carousel.current.previous}
              goToSlide={carousel.current.goToSlide}
              carouselState={{ currentSlide: 2 }}
            />
          }
          renderButtonGroupOutside
        >
          {items}
        </Carousel>
      )}
    </div>
  )

  const renderTestimonials = size.width && size.width < 750 ? mobileCarousel : desktopCarousel

  return (
    <TestimonialsContainer backgroundColor={backgroundColor}>
      <Content isVariant={isVariant}>{renderTestimonials}</Content>
    </TestimonialsContainer>
  )
}

export default Testimonials
