import { useMemo, useCallback, useRef, useState } from 'react'
import Link from 'next/link'
import CardPublication from './CardPublication'
import { ArrowUp } from '~/assets/svg'
import {
  Container,
  IndicatorBox,
  PublicationsWrapper,
  IndicatorContent,
  Indicator,
  PublicationTitle,
  PublicationText,
  TitleContainer,
  ControlButton,
  Icon,
  CarouselWrapper,
  MobileCarouselContainer,
  DesktopCarouselContainer,
} from './styles'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { IPost } from 'types'

type SkipCallbackOptions =
  | {
      skipBeforeChange?: boolean
      skipAfterChange?: boolean
    }
  | boolean

interface ISectionPublications {
  content: IPost[]
  backgroundColor?: string
}

export const SectionPublications = ({ content, backgroundColor = 'white' }: ISectionPublications) => {
  const carousel = useRef<Carousel>({} as Carousel)
  const [currentSlide, setCurrentSlide] = useState(0)

  const getAllDots = (dotCurrentSlide: number, goToSlide: (slide: number, skipCallbacks?: SkipCallbackOptions) => void) =>
    content.map((_content, index) => {
      const active = dotCurrentSlide === index ? 'active' : ''
      return <Indicator key={index.toString()} className={active} onClick={() => goToSlide(index)} />
    })

  interface IButtonGroup {
    previous: (slidesHavePassed: number) => void
    goToSlide: (slide: number, skipCallbacks?: SkipCallbackOptions) => void
  }

  const ButtonGroup = useCallback(
    ({ previous, goToSlide }: IButtonGroup) => {
      if (currentSlide) {
        setCurrentSlide(carousel.current.state?.currentSlide)
      }
      return (
        <IndicatorBox>
          {currentSlide !== 0 && (
            <ControlButton type="button" className="btn" onClick={() => previous(1)}>
              <Icon loading="lazy" src="/assets/svg/arrow-left-black.svg" />
            </ControlButton>
          )}
          <IndicatorContent> {getAllDots(currentSlide, goToSlide)} </IndicatorContent>
          {currentSlide + 1 !== content.length && (
            <ControlButton type="button" className="btn" onClick={() => goToSlide(currentSlide + 1)}>
              <Icon loading="lazy" src="/assets/svg/arrow-right-black.svg" />
            </ControlButton>
          )}
        </IndicatorBox>
      )
    },
    [getAllDots, carousel, currentSlide, content, currentSlide, setCurrentSlide]
  )

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  const cardsPublication = content.map((card: IPost) => (
    <CardPublication key={card.id} image={card.image} alt={card.alt} title={card.title} link={card.link} tag={card.tag} />
  ))

  const mobileCarousel = useMemo(
    () => (
      <CarouselWrapper>
        {!!carousel.current && (
          <Carousel
            ref={carousel}
            swipeable
            draggable
            showDots={false}
            arrows={false}
            deviceType="desktop"
            responsive={responsive}
            ssr
            customTransition="all 0.5s"
            transitionDuration={500}
            containerClass="carousel-container"
            customButtonGroup={<ButtonGroup previous={carousel?.current?.previous} goToSlide={carousel?.current?.goToSlide} />}
            renderButtonGroupOutside
          >
            {cardsPublication}
          </Carousel>
        )}
      </CarouselWrapper>
    ),
    [carousel, responsive, cardsPublication]
  )

  return (
    <Container backgroundColor={backgroundColor}>
      <PublicationsWrapper>
        <TitleContainer>
          <PublicationTitle>Blog da Provi</PublicationTitle>
          <PublicationText>Acesse o blog e leia as últimas publicações.</PublicationText>
          <Link href="/blog" passHref legacyBehavior>
            <>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              Acessar o blog
              <ArrowUp />
            </>
          </Link>
        </TitleContainer>

        <MobileCarouselContainer>{mobileCarousel}</MobileCarouselContainer>
        <DesktopCarouselContainer>{cardsPublication}</DesktopCarouselContainer>
      </PublicationsWrapper>
    </Container>
  )
}
