import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const SummitContainer = styled.section`
  padding: 45px 24px 50px 24px;
  color: ${theme.colors.white100};
  background-color: ${theme.colors.bluePrimaryDark};

  @media (min-width: 1040px) {
    padding: 0;

    background-image: url('/assets/svg/isa-summit-pattern.svg');
    background-position: right bottom;
    background-size: auto 35%;
    background-repeat: no-repeat;
  }
`

export const SummitContent = styled.div`
  @media (min-width: 1040px) {
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
    padding: 93px 0 114px 0;

    display: grid;
    grid-template-columns: 1fr repeat(12, minmax(0, 80px)) 1fr;
    gap: 24px;
  }
`
