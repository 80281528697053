import styled from 'styled-components'
import { theme } from '@provi/provi-components'

interface INavigator {
  marginTop: number
}

export const FAQNavigatorWrapper = styled.aside<INavigator>`
  background-color: white;
  padding: 10px 24px 10px;
  min-width: 288px;
  max-height: 300px;

  ${theme.breakpoints.down('md')} {
    padding: 13px 24px 15px;
    background: ${theme.colors.bluePrimary};
  }

  ${theme.breakpoints.up('md')} {
    ${({ marginTop }) => `margin-top: ${marginTop}px;`}
    transition: all 400ms;
  }
`

export const FAQNavigatorList = styled.ul`
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`

export const FAQNavigatorItem = styled.li`
  padding: 5px;
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
`

export const FAQNavifatorLinkText = styled.span`
  color: #1c1c1e;
  cursor: pointer;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
`
export const A = styled.a`
  text-decoration: none;
  color: #fff !important;
  text-decoration: underline;
`
