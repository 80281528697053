import { Breadcrumbs, ButtonV3 as Button } from '@provi/provi-components'
import { IBreadcrumb } from 'types'
import theme from '~/styles/theme'

import { BannerContainer, BannerContent, ButtonContainer, Description, SubDescription, BannerTitle, A } from './styles'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

interface IBreadcrumbs {
  color?: string
  currentColor?: string
  levels: string[]
}

interface IBanner {
  backgroundColor: string
  backgroundURL: string
  breadcrumbsProps?: IBreadcrumbs
  description: string
  subDescription?: string
  title: string
  buttonCallback?: (id: string) => void
  buttonText?: string
  buttonMobileWidth?: string
}

const DEFAULT_BREADCRUMBS_PROPS = {
  levels: [],
}

const useBreadcrumpsProps = (breadcrumbsProps: IBreadcrumb) => {
  const { levels } = breadcrumbsProps
  const newLevels: any[] = []
  const route = useRouter()
  levels.forEach((level: string) => {
    if (route && level === 'ISA' && route.pathname !== '/isa') {
      return newLevels.push(
        <NextLink href="/isa" passHref legacyBehavior>
          <A>{level}</A>
        </NextLink>
      )
    }
    if (level === 'Início') {
      newLevels.push(
        <NextLink href="/" passHref legacyBehavior>
          <A>{level}</A>
        </NextLink>
      )
    } else {
      newLevels.push(level)
    }
  })
  return newLevels
}

export const Banner = ({
  backgroundColor,
  backgroundURL,
  breadcrumbsProps = DEFAULT_BREADCRUMBS_PROPS,
  description,
  subDescription = '',
  title,
  buttonCallback,
  buttonText,
  buttonMobileWidth,
}: IBanner): JSX.Element => {
  const levels = useBreadcrumpsProps(breadcrumbsProps)

  return (
    <BannerContainer backgroundURL={backgroundURL} backgroundColor={backgroundColor} className="banner-container">
      <BannerContent>
        {breadcrumbsProps && (
          <Breadcrumbs
            levels={levels}
            color={breadcrumbsProps?.color || '#000000'}
            currentColor={breadcrumbsProps?.currentColor || theme.provi.colors.bluePrimaryDark}
          />
        )}
        <BannerTitle hasBreadcrumbs={!!breadcrumbsProps}>{title}</BannerTitle>
        <Description>{description}</Description>
        {subDescription && <SubDescription>{subDescription}</SubDescription>}
        {buttonText && buttonCallback && buttonMobileWidth ? (
          <ButtonContainer>
            <Button onClick={buttonCallback} text={buttonText} mobileWidth={buttonMobileWidth} />
          </ButtonContainer>
        ) : null}
      </BannerContent>
    </BannerContainer>
  )
}
