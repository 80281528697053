import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const FAQQuestionListWrapper = styled.main`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;

  ${theme.breakpoints.down('md')} {
    min-height: 60vh;

    .collapse-content {
      > div {
        padding-bottom: 18px;
      }

      div:last-child {
        border-bottom: none;
      }
    }
  }
`

export const FAQSectionWrapper = styled.section`
  margin-bottom: 30px;
  margin-top: 40px;
`

export const FAQSectionTitle = styled.h2`
  margin-bottom: 30px;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #2647d7;
`
