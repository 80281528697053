import styled from 'styled-components'
import { theme } from '@provi/provi-components'

interface IButtonWrapper {
  isVariant?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  max-width: 496px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ButtonWrapper = styled.div<IButtonWrapper>`
  margin-top: 6px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${({ isVariant }) => (isVariant ? 'space-between' : 'flex-start')};
  align-items: center;

  .MuiButton-contained.Mui-disabled {
    background-color: ${theme.colors.greyLight};

    .MuiButton-label {
      color: ${theme.colors.greyPrimary};
    }
  }
`

export const SuccessMessage = styled.p`
  max-width: 360px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
  margin: 31px 0 125px 0;
  align-self: flex-start;

  ${theme.breakpoints.down('md')} {
    margin: 31px 0;
  }
`

export const ErrorMessage = styled.span`
  margin-top: 16px;
  align-self: center;
`

export const InputForm = styled.form`
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  transition: all 0.4s ease-in-out;

  .input-message {
    resize: none;
    height: 100%;
    margin: 5px 5px 5px 0;

    ::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 10px;
      border: none;
    }

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track-piece {
      background-color: #f5f5f5;
    }
  }

  div:nth-child(3) {
    label > div {
      height: 75px;
    }
  }
`

export const LinkForm = styled.span`
  a {
    color: black;
    text-decoration: underline;
    letter-spacing: 0.005em;
    font-weight: 600;
  }
`
