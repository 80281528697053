import styled from 'styled-components'

interface IContainer {
  backgroundColor: string
}

export const FAQContainer = styled.div<IContainer>`
  color: ${({ theme }) => theme.provi.colors.bluePrimaryDark};
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    background-image: url('/assets/svg/pattern-banners/faq_banner_frame.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 140px;
  }
`

export const FAQBreadcrumbWrapper = styled.div`
  display: flex;
`

export const FAQHeaderContent = styled.div`
  padding: 8px 24px;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    max-width: 1016px;
    margin: 0 auto;
    padding: 16px 0 35px 312px;

    .breadcrumb-container {
      justify-content: center;
    }
  }
`

export const FAQTitle = styled.h1`
  font-family: 'Montserrat', 'sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: white;
  margin-top: 40px;
`
export const A = styled.a`
  text-decoration: none;
  color: #fff !important;
  text-decoration: underline;
`

export const CurrentCrumb = styled.span`
  color: #fff;
`
