import Card from '../Cards/Card'
import { cardsMentoring, cardsCommunity } from './values'
import { ActionContainer, Content, Title, CardsWrapper, SectionWrapper, TitleCard } from './styles'

const ActionsProvi = () => (
  <ActionContainer>
    <Content>
      <SectionWrapper>
        <Title>Conheça as nossas ações de incentivo</Title>
      </SectionWrapper>
      <SectionWrapper>
        <CardsWrapper>
          <TitleCard>Comunidade</TitleCard>
          {cardsCommunity.map((item) => (
            <Card key={item.title} title={item.title} text={item.text} icon={item.icon} />
          ))}
        </CardsWrapper>
        <CardsWrapper>
          <TitleCard>Mentoria</TitleCard>
          {cardsMentoring.map((item) => (
            <Card key={item.title} title={item.title} text={item.text} icon={item.icon} />
          ))}
        </CardsWrapper>
      </SectionWrapper>
    </Content>
  </ActionContainer>
)

export default ActionsProvi
