import { Icon } from './styles'

export const cardsCommunity = [
  {
    icon: (
      <Icon
        src="/assets/svg/pin.svg"
        alt="Ilustração simplificada de uma troca de conhecimento, com três círculos e um símbolo de mais."
      />
    ),
    title: 'Troca',
    text: 'Temos uma das maiores comunidades de profissionais de tecnologia do Brasil.',
  },
  {
    icon: <Icon src="/assets/svg/content.svg" alt="Ilustração simplificada de uma folha de papel." />,
    title: 'Conteúdo',
    text: 'Desenvolvemos uma série de materiais para cada etapa da jornada de busca por trabalho.',
  },
  {
    icon: <Icon src="/assets/svg/connection.svg" alt="Ilustração de duas setas diagonais se encontrando." />,
    title: 'Conexão',
    text: 'Firmamos parcerias com empresas que estão em busca de novos talentos.',
  },
]

export const cardsMentoring = [
  {
    icon: <Icon src="/assets/svg/mentoring.svg" alt="Ilustração simplificada de balões de fala." />,
    title: 'Acompanhamento',
    text: 'Contamos com uma equipe de especialistas em carreiras digitais.',
  },
  {
    icon: <Icon src="/assets/svg/stepByStep.svg" alt="Ilustração simplificada de um gráfico com tendência ascendente." />,
    title: 'Passo a passo ',
    text: 'Ensinamos como desenvolver habilidades para enfrentar os desafios do mercado.',
  },
  {
    icon: (
      <Icon
        src="/assets/svg/streaming.svg"
        alt="Ilustração de um círculo com semi-círculos ao seu redor, representando sinal de internet."
      />
    ),
    title: '#EuTambémJáFuiJúnior',
    text: 'Estimulamos o diálogo com profissionais que já passaram por desafios semelhantes.',
  },
]
