import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

import Collapse from '@material-ui/core/Collapse'
import AddIcon from '@material-ui/icons/Add'

interface ContainerProps {
  isLast: boolean
  containerWidth: string
  variant: boolean
}

interface RowProps {
  titleColor: string
}

interface IIconButton {
  iconColor?: string
}

export const Container = styled.div<ContainerProps>`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ containerWidth }) => containerWidth || '100%'};
  border-top: 2px solid ${({ variant }) => (variant ? theme.colors.bluePrimaryDark : theme.colors.bluePrimary)};
  color: #000000;
  cursor: pointer;
  padding-bottom: 14px;

  ${({ isLast, variant }) =>
    isLast &&
    css`
      border-bottom: 2px solid ${variant ? theme.colors.bluePrimaryDark : theme.colors.bluePrimary};
    `}

  ${theme.breakpoints.down('md')} {
    padding-bottom: 24px;
    .MuiCollapse-entered {
      width: 100% !important;
    }
  }
`

export const Row = styled.div<RowProps>`
  display: inline-flex;
  justify-content: space-between;
  justify-self: center;
  align-items: flex-start;
  margin-top: 18px;

  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
  color: ${({ titleColor }) => titleColor || '#000000'};

  ${theme.breakpoints.down('md')} {
    margin-top: 8px;
    font-size: 15.84px;
    line-height: 24px;
  }
`

export const Content = styled.div`
  width: 100%;
  margin-top: 25px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
  color: #000000;

  ${theme.breakpoints.down('md')} {
    margin-top: 11px;
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.005em;
  }
`

export const FeaturedTitle = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: 23px;
  line-height: 36px;
  font-weight: 500;
  color: ${theme.colors.bluePrimary};
`
export const QuestionTitle = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
`

export const IconButton = styled.button<IIconButton>`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: ${({ iconColor }) => iconColor || '#000000'};
`

export { Collapse, AddIcon }
