import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const NextEventContainer = styled.div`
  padding: 68px 24px 30px;
  width: 100%;
  max-width: 1064px;

  svg {
    path {
      stroke: white;
    }
  }

  a {
    width: 148px;
  }

  @media (min-width: 670px) {
    display: flex;

    a {
      width: 180px;
    }
  }
`

export const NextEventContent = styled.div`
  max-width: 288px;

  @media (min-width: 670px) {
    margin-left: 24px;
  }
`

export const Img = styled.img`
  max-width: 312px;
  width: 100%;
  height: auto;
  object-fit: cover;

  @media (min-width: 670px) {
    max-width: 392px;
    height: 294px;
    border-radius: 16px;
  }
`

export const Title = styled.h2`
  font-weight: 500;
  color: ${theme.colors.bluePrimary};
  font-size: 22.81px;
  line-height: 36px;
  margin: 22px 0 28px;

  @media (min-width: 670px) {
    font-size: 25px;
    line-height: 32px;
    letter-spacing: 0.0025em;
    margin-bottom: 15px;
  }
`

export const Text = styled.p`
  font-weight: 500;
  font-size: 15.84px;
  line-height: 24px;
  max-width: 312px;
  margin-bottom: 26px;

  @media (min-width: 670px) {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0075em;
    margin-bottom: 24px;
  }
`
