import React from 'react'
import { ButtonV3 as Button } from '@provi/provi-components'

import { IsaVideoWrapper, IsaTextContainer, IsaTitle, IsaText, Iframe, ThumbnailImage } from './IsaVideoContainer.styles'

interface IIsaVideo {
  isVariant?: boolean
  title?: string
  text: string
  buttonText?: string
  linkRef?: string
  withImage?: boolean
  srcRef?: string
  targetBlank?: boolean
  altImg?: string
}

const IsaVideoContainer = ({
  isVariant,
  title,
  text,
  buttonText,
  linkRef,
  withImage,
  srcRef,
  targetBlank,
  altImg,
}: IIsaVideo) => (
  <IsaVideoWrapper isVariant={isVariant}>
    <IsaTextContainer>
      {IsaTitle && <IsaTitle>{title}</IsaTitle>}

      <IsaText isVariant={isVariant}>{text}</IsaText>
      <Button
        text={buttonText || 'Acesse os vídeos'}
        color={isVariant ? 'primary' : 'white'}
        width="188px"
        mobileWidth="172px"
        buttonProps={{
          href: linkRef || 'https://www.youtube.com/channel/UC0wPjnokNj847ndDPZaDBgQ',
          target: targetBlank ? '_blank' : '',
          rel: 'noopener noreferrer',
        }}
      />
    </IsaTextContainer>
    {withImage ? (
      <ThumbnailImage src={srcRef} alt={altImg} />
    ) : (
      <Iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/GM6KpwdtFoc"
        title="Prévia de visualização Providers | Um time que tem orgulho do seu propósito"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    )}
  </IsaVideoWrapper>
)

export default IsaVideoContainer
