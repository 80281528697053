import styled from 'styled-components'
import { theme } from '@provi/provi-components'

interface IContainer {
  backgroundColor: string
  backgroundURL: string
}

interface ITitle {
  hasBreadcrumbs?: boolean
}

export const BannerContainer = styled.div<IContainer>`
  color: ${theme.colors.bluePrimaryDark};
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${theme.breakpoints.up('md')} {
    background-image: ${({ backgroundURL }) => `url('${backgroundURL}')`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export const BannerContent = styled.div`
  padding: 8px 24px;

  ${theme.breakpoints.up('md')} {
    max-width: 1064px;
    margin: 0 auto;
    padding: 16px 24px 80px 24px;

    .breadcrumb-container {
      justify-content: center;
    }
  }
`

export const BannerTitle = styled.p<ITitle>`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  margin: ${({ hasBreadcrumbs }) => (hasBreadcrumbs ? '50px 0 22px 0' : '70px 0 22px 0')};

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.02em;
    margin: ${({ hasBreadcrumbs }) => (hasBreadcrumbs ? '31px 0 7px 0' : '51px 0 7px 0')};
  }
`

export const Description = styled.h1`
  font-weight: 500;
  font-size: 27.37px;
  line-height: 40px;

  letter-spacing: 0.0025em;

  ${theme.breakpoints.up('md')} {
    font-size: 40px;
    line-height: 48px;

    max-width: 50%;
  }
`

export const SubDescription = styled.h2`
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.01em;

  ${theme.breakpoints.up('md')} {
    font-size: 32px;
    line-height: 40px;
    max-width: 50%;
  }
`
export const ButtonContainer = styled.div`
  height: 80px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  ${theme.breakpoints.up('md')} {
    height: 100px;
    display: flex;
    align-items: flex-end;
  }
`

export const A = styled.a`
  text-decoration: none;
  color: black !important;
  text-decoration: underline;
`
