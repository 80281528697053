import { useCallback, useMemo, useState } from 'react'
import { IFormWithMessage } from 'types'
import { sendFaqForm, sendSobreNosForm } from '~/lib/api'
import { InputFieldV3 as Input, ButtonV3 as Button } from '@provi/provi-components'
import { canKeyboardEvent, isNameValid, nameHasError, isEmailValid, emailHasError, isMessageValid } from './validation'
import { ErrorMessage, ButtonWrapper, Container, SuccessMessage, InputForm, LinkForm } from './styles'
import Link from 'next/link'

export const Inputs = ({ information, setInformation, isVariant }: IFormWithMessage) => {
  const [step, setStep] = useState(1)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInformation({
        ...information,
        [event.target.name]: event.target.value,
      })
    },
    [setInformation, information]
  )

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      setErrorMessage('')
      event.preventDefault()

      if (isLoading) {
        return
      }

      setIsLoading(true)
      const response = await (isVariant ? sendFaqForm(information) : sendSobreNosForm(information))
      setIsLoading(false)

      if (response?.error) {
        setErrorMessage(response.message || 'Ocorreu um erro inesperado')
        return
      }

      setStep(step + 1)
    },
    [information, sendSobreNosForm, errorMessage, setErrorMessage]
  )

  const renderInputs = useMemo(
    () => (
      <InputForm key={1} onSubmit={handleSubmit}>
        <Input
          label="Seu Nome"
          placeholder="Vivian Maier"
          width="496px"
          value={information.name}
          name="name"
          onChange={handleChange}
          isValid={isNameValid(information)}
          hasError={nameHasError(information)}
          errorMessage="Infome seu nome e sobrenome"
          required
          data-testid="input-name"
        />
        <Input
          label="Seu e-mail"
          placeholder="vivianmaier@email.com"
          width="496px"
          value={information.email}
          name="email"
          onChange={handleChange}
          isValid={isEmailValid(information)}
          hasError={emailHasError(information)}
          errorMessage="É necessário informar seu e-mail"
          data-testid="input-email"
        />
        <Input
          label={isVariant ? 'Pergunta' : 'Mensagem'}
          placeholder="Escreva aqui"
          width="496px"
          className="input-message"
          value={information.message}
          name="message"
          onChange={handleChange}
          isValid={isMessageValid(information)}
          errorMessage="É necessário informar sua mensagem"
          as="textarea"
          maxLength="300"
          data-testid="input-message"
        />
        <LinkForm>
          Ao enviar o formulário, você concorda com os{' '}
          <Link href="/termos-de-uso" legacyBehavior>
            <a href="/termos-de-uso" target="_blank">
              Termos de uso e Política de privacidade
            </a>
          </Link>{' '}
          da Provi.
        </LinkForm>
        <ButtonWrapper>
          <Button
            text="Enviar pergunta"
            onClick={handleSubmit}
            color="primary"
            disabled={isLoading || !canKeyboardEvent(information)}
            type="submit"
            data-testid="button"
          />
        </ButtonWrapper>
      </InputForm>
    ),
    [handleSubmit, information, setInformation, canKeyboardEvent, isNameValid, nameHasError, isLoading]
  )

  const successMessage = useMemo(
    () => (
      <InputForm key={2}>
        <SuccessMessage>
          Suas respostas foram enviadas.
          <br />
          Obrigada!
          <br />
        </SuccessMessage>
      </InputForm>
    ),
    [handleSubmit]
  )

  return (
    <Container>
      {step === 1 ? renderInputs : successMessage}
      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  )
}
