import React from 'react'

export const SearchIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.9621 9.96252L12.9996 13.0001M11.5 6.25C11.5 9.1495 9.1495 11.5 6.25 11.5C3.35051 11.5 1 9.1495 1 6.25C1 3.35051 3.35051 1 6.25 1C9.1495 1 11.5 3.35051 11.5 6.25Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
