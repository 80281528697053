import { IInformation as IInformationForm } from 'types'
import axios from 'axios'
import { ROOT_URL } from '~/constants'

export const api = axios.create({
  baseURL: ROOT_URL?.MS_SITE || '',
})

const TOKEN_FRONT_END =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjEsImVtYWlsIjoiam9hb0Bwcm92aS5jb20uYnIiLCJleHAiOiIzNmQifQ.yBOqt3uFDYs7byxW4ZczfOzxlUPgC8CZcWhlhPKBtn4'

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = TOKEN_FRONT_END

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

interface IPartners {
  id: number
  label: string
}

export const getPartners = async (): Promise<IPartners[] | string> => {
  try {
    const { data } = await api.get('/partners')
    return data
  } catch (error: any) {
    return error?.response?.data?.error?.message || error?.response?.data?.error?.name || 'Ocorreu um erro inesperado'
  }
}
export const sendSobreNosForm = async (body: IInformationForm): Promise<any> => {
  try {
    const response = await api.post('/form/generic-not-found', body)
    return response.data
  } catch (error: any) {
    return error?.response?.data
  }
}

export const sendFaqForm = async (body: IInformationForm): Promise<any> => {
  try {
    const response = await api.post('/form/answer-not-found', body)
    return response.data
  } catch (error: any) {
    return error?.response?.data
  }
}
