import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const FormContainer = styled.section`
  width: 100%;
  background: ${theme.colors.bluePrimaryLight};
`

export const FormSection = styled.section`
  margin: 0 auto;
  padding: 36px 24px;
  max-width: 1064px;
  display: flex;
  justify-content: flex-start;
  gap: 128px;

  @media (max-width: 860px) {
    justify-content: space-between;
    gap: 8px;
  }

  ${theme.breakpoints.down('md')} {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`

export const FormInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 26px;
  max-width: 288px;

  ${theme.breakpoints.down('md')} {
    max-width: 496px;
    margin: 2px 0 29px 0;
  }
`

export const FormTitle = styled.h2`
  font-weight: 500;
  font-size: 31.25px;
  line-height: 40px;

  ${theme.breakpoints.down('ms')} {
    font-size: 22.81px;
    line-height: 36px;
    letter-spacing: 0.005em;
  }
`

export const FormDescription = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;

  ${theme.breakpoints.down('md')} {
    font-size: 15.84px;
    line-height: 24px;
    letter-spacing: 0.005em;
  }
`
