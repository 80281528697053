import styled, { css } from 'styled-components'

interface IContainer {
  backgroundColor: string
  isVisible: boolean
}

interface IImg {
  isVariant?: boolean
}

type IContentTestimonial = IImg

export const Container = styled.li<IContainer>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  background: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.6s ease;

  &.hide {
    opacity: 0;
  }
`

export const ContentTestimonial = styled.div<IContentTestimonial>`
  margin-left: ${({ isVariant }) => (isVariant ? '51%' : '8%')};
  margin-top: 110px;

  animation: fadeOut 500ms ease reverse both;

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @media (max-width: 750px) {
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    > div {
      margin: 0 auto;
    }
  }
`

export const Img = styled.img<IImg>`
  position: absolute;
  width: 495px;
  height: 504px;
  left: 50%;
  margin-top: 32px;
  margin-left: 0;
  object-fit: cover;

  ${({ isVariant }) =>
    isVariant &&
    css`
      height: 392px;
      left: 0;
      margin-left: calc((100% - 1016px) / 2);
    `}

  animation: bounceOutRight 800ms ease-in reverse both;

  @keyframes bounceOutRight {
    20% {
      opacity: 1;
      transform: translate3d(-20px, 0, 0);
    }

    to {
      opacity: 0;
      transform: translate3d(2000px, 0, 0);
    }
  }

  @media (max-width: 1044px) {
    width: 496px;
    height: 504px;
    left: 60%;
    margin-left: 0;
    top: 50px;

    ${({ isVariant }) =>
      isVariant &&
      css`
        width: 347.2px;
        height: 277.2px;
        left: 0;
        margin-left: 24px;
      `}
  }

  @media (max-width: 750px) {
    width: unset;
    object-fit: cover;
    height: ${({ isVariant }) => (isVariant ? '238px' : '200px')};
    position: relative;
    margin: 0 auto 38px;
    left: 0;
    top: 0;
  }
`
