import { useRef, useState } from 'react'
import ArticleCard from './ArticleCard'
import { IArticles } from '~/hooks/Isa'
import { IButtonGroup, SkipCallbackOptions } from 'types'
import { ArrowLeft, ArrowRight } from '~/assets/svg'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import {
  Container,
  ControlButton,
  CarouselWrapper,
  IndicatorBox,
  Indicator,
  Section,
  IndicatorContent,
  NewsSectionTitle,
} from './styles'

interface INewsSection {
  content: IArticles[]
  title?: string
}

const NewsSection = ({ content = [], title = 'Provi na mídia' }: INewsSection) => {
  const carousel = useRef<Carousel>({} as Carousel)
  const [isCurrentSlide, setIsCurrentSlide] = useState(0)

  const getAllDots = (currentSlide: number, goToSlide: (slide: number, skipCallbacks?: SkipCallbackOptions) => void) => {
    setTimeout(() => {
      // wait for animation to end and then rerender
      setIsCurrentSlide(currentSlide)
    }, 400)

    return content.map((_content, index) => {
      const isDotActive =
        currentSlide === index || currentSlide === index - content.length || currentSlide === index + content.length

      const slideDot = ['smallTablet', 'mobile'].includes(carousel?.current?.state?.deviceType || '') ? index + 2 : index

      return (
        <Indicator
          aria-label="Círculo clicável no menu do carrossel"
          key={index.toString()}
          isActive={isDotActive}
          onClick={() => goToSlide(slideDot)}
        />
      )
    })
  }

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }: IButtonGroup) => {
    const {
      carouselState: { currentSlide, deviceType },
    } = rest
    return (
      <IndicatorBox>
        <ControlButton type="button" onClick={() => previous(1)}>
          <ArrowRight color="#FFF" />
        </ControlButton>
        <IndicatorContent>
          {getAllDots(
            ['smallTablet', 'mobile'].includes(deviceType || '')
              ? currentSlide - content.length - 2
              : currentSlide - content.length,
            goToSlide
          )}
        </IndicatorContent>
        <ControlButton type="button" onClick={() => next(1)}>
          <ArrowLeft color="#FFF" />
        </ControlButton>
      </IndicatorBox>
    )
  }

  const cardsPublication = content.map((card: IArticles, index: number) => (
    <ArticleCard
      key={index.toString()}
      isActive={
        isCurrentSlide === index || isCurrentSlide === index - content.length || isCurrentSlide === index + content.length
      }
      image={card.image}
      title={card.title}
      text={card.text}
      link={card.article_link}
    />
  ))

  const responsive = {
    ultrawideDesktop: {
      breakpoint: { max: 3000, min: 2400 },
      items: 4,
      slidesToSlide: 1,
      partialVisibilityGutter: 20,
    },
    desktop: {
      breakpoint: { max: 2399, min: 1600 },
      items: 3,
      slidesToSlide: 1,
      partialVisibilityGutter: 20,
    },
    laptop: {
      breakpoint: { max: 1599, min: 1200 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1199, min: 950 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 10,
    },
    smallTablet: {
      breakpoint: { max: 949, min: 800 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 150,
    },
    mobile: {
      breakpoint: { max: 799, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
  }

  return (
    <Section>
      <Container>
        <NewsSectionTitle>{title}</NewsSectionTitle>
        <CarouselWrapper>
          <Carousel
            ref={carousel}
            swipeable
            draggable
            showDots={false}
            arrows={false}
            partialVisible
            responsive={responsive}
            deviceType="desktop"
            ssr
            infinite
            customTransition="all .5s"
            transitionDuration={500}
            customButtonGroup={
              <ButtonGroup
                next={carousel.current.next}
                previous={carousel.current.previous}
                goToSlide={carousel.current.goToSlide}
                carouselState={carousel.current.state}
              />
            }
            renderButtonGroupOutside
          >
            {cardsPublication}
          </Carousel>
        </CarouselWrapper>
      </Container>
    </Section>
  )
}

export default NewsSection
