import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface ISectionWrapper {
  isVariant?: boolean
}

type IContainer = ISectionWrapper

interface IInfoContainer {
  isMiddle?: boolean
}

interface INumberComplement {
  isBig?: boolean
}

export const SectionWrapper = styled.div<ISectionWrapper>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 568px;
  background: ${theme.colors.bluePrimary};
  background-image: url('/assets/svg/LightCircle.svg'), url('/assets/svg/WhiteCircle.svg'), url('/assets/svg/DarkCircle.svg');
  background-position: -200px 260px, calc(100% + 125px) 250px, center -230px;
  background-size: 344px, 385px, 385px;
  background-repeat: no-repeat;

  @media (max-width: 980px) {
    background-position: calc(100% + 75px) calc(100% + 100px), calc(100% + 100px) -100px, -130px center;
    background-size: 200px;
    height: auto;
  }

  ${({ isVariant }) =>
    isVariant &&
    css`
      background-image: url('/assets/svg/LightCircle.svg'), url('/assets/svg/WhiteCircle.svg'),
        url('/assets/svg/DarkCircle.svg'), url('/assets/svg/DarkCircle.svg');
      background-position: -80px 320px, calc(100% + 160px) -220px, calc(100% + 320px) 250px, 200px -300px;
      background-size: 344px, 385px, 385px, 385px;

      @media (max-width: 1230px) {
        background-position: -115px calc(100% - 30px), calc(100% + 100px) -40px, 35% -120px, 60% calc(100% + 130px);
        background-size: 200px;
      }

      @media (max-width: 980px) {
        background-position: -115px calc(100% + 30px), calc(100% + 100px) -40px, -130px 200px, calc(100% + 130px) 430px;
        background-size: 200px;
      }
    `}
`

export const Container = styled.div<IContainer>`
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  padding: 64px 28px;
  display: flex;

  ${({ isVariant }) =>
    isVariant &&
    css`
      @media (max-width: 1230px) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 35px 0;
        padding: 64px 32px;

        div {
          place-items: center;
          margin: 0;
          padding: 0;
          align-items: flex-start;
          justify-content: flex-start;

          p {
            margin: 0;
          }
        }

        div:nth-child(3),
        div:nth-child(4) {
          margin-left: 108px;
        }
      }
    `}

  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
    gap: 62px;
    align-items: center;

    div:nth-child(3),
    div:nth-child(4) {
      margin-left: unset;
    }
  }

  ${theme.breakpoints.down('md')} {
    padding: 16px 24px 42px 24px;
    align-items: flex-start;
  }
`

export const InfoContainer = styled.div<IInfoContainer>`
  max-width: 392px;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  color: ${theme.colors.white100};

  ${({ isMiddle }) =>
    isMiddle &&
    css`
      margin-top: 180px;
      margin-bottom: 36px;

      @media (max-width: 980px) {
        margin-top: 0;
        margin-bottom: 0;
        align-self: flex-end;
      }
    `}

  ${theme.breakpoints.down('md')} {
    max-width: 230px;
  }
`

export const Number = styled.strong`
  display: flex;
  align-items: flex-end;
  font-weight: 800;
  font-size: 96px;
  line-height: 96px;

  ${theme.breakpoints.down('md')} {
    font-size: 48.83px;
    line-height: 60px;
  }
`

export const NumberComplement = styled.span<INumberComplement>`
  font-weight: 500;
  font-size: ${({ isBig }) => (isBig ? '64px' : '32px')};
  line-height: ${({ isBig }) => (isBig ? '72px' : '50px')};

  ${theme.breakpoints.down('md')} {
    font-size: ${({ isBig }) => (isBig ? '22.81px' : '19.1px')};
    line-height: 36px;
    letter-spacing: 0.005em;
  }
`

export const Information = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
  max-width: 288px;
  margin-left: 104px;

  ${theme.breakpoints.down('md')} {
    margin-left: 0;
    font-size: 15.84px;
    line-height: 24px;
    letter-spacing: 0.005em;
  }
`
