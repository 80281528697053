import { Container, Img, Title, Link, Text, TextLink } from './CardBeforeEvent.styles'
import { ArrowUp } from '~/assets/svg'
import { INextEvent } from 'types'

const CardBeforeEvent = ({ image, title, text, link }: INextEvent) => (
  <Container>
    <Img
      src={image}
      alt="À esquerda, fotografia de uma mulher de cabelo raspado olhando o notebook e fazendo anotações. À direita fotografia de uma mulher de costas e cabelo preto preso em uma reunião remota. "
    />

    <Title>{title}</Title>

    <Text>{text}</Text>

    <Link href={link} target="_blank" rel="noopener noreferrer">
      <TextLink>Saiba mais</TextLink> <ArrowUp />
    </Link>
  </Container>
)

export default CardBeforeEvent
