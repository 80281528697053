import { PartnersContainer, PartnersContent, PartnersGrid, PartnerLogo, PartnersTitle } from './styles'

export interface ISchool {
  image: string
  alt: string
}

interface IPartners {
  logoSchools: ISchool[]
}

export const Partners = ({ logoSchools }: IPartners) => (
  <PartnersContainer>
    <PartnersContent>
      <PartnersTitle>Escolas parceiras no modelo ISA</PartnersTitle>
      <PartnersGrid>
        {logoSchools.map((school: ISchool) => (
          <PartnerLogo key={school.alt} loading="lazy" src={school.image} alt={school.alt} />
        ))}
      </PartnersGrid>
    </PartnersContent>
  </PartnersContainer>
)
