import { CardPublicationContainer, Title, Tag, Wrapper, CardTexts, ImageContainer } from './styles'
import Image from 'next/legacy/image'
import Link from 'next/link'

interface ISectionPublications {
  image: string
  title: string
  link: string
  alt: string
  tag?: string
  className?: string
}

const CardPublication = ({ image = '', alt = '', tag = '', title = '', link = '', className }: ISectionPublications) => {
  link = link.replace('https://blog.provi.com.br/', '/blog/')

  return (
    <CardPublicationContainer className={`card-publication ${className}`}>
      <Link href={link} passHref legacyBehavior>
        <Wrapper>
          {image && (
            <ImageContainer>
              <Image src={image} alt={alt} layout="fill" objectFit="cover" />
            </ImageContainer>
          )}
          <CardTexts>
            {tag && <Tag>{tag}</Tag>}
            <Title>{title}</Title>
          </CardTexts>
        </Wrapper>
      </Link>
    </CardPublicationContainer>
  )
}

export default CardPublication
