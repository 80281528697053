import { CardContainer, Description, Icon, CardTitle } from './styles'

interface ICard {
  description: string
  iconPath: string
  title: string
  alt: string
}

const Card = ({ description, iconPath, title, alt }: ICard) => (
  <CardContainer>
    <Icon loading="lazy" src={iconPath} alt={alt} />
    <CardTitle>{title}</CardTitle>
    <Description>{description}</Description>
  </CardContainer>
)

export default Card
