import { IInfo } from '..'
import { InfoContainer, Card, Cards, CardValue, CardDescription, InfoTitle, Description } from './styles'

const Infos = ({ info }: IInfo) => (
  <InfoContainer>
    <InfoTitle>ISA Summit</InfoTitle>
    <Description>
      O ISA Summit é o primeiro evento brasileiro dedicado ao modelo de sucesso compartilhado. Em 6 dias de duração, contamos
      com 17 pessoas convidadas que compartilharam suas experiências e aprendizados atuando nesse modelo.
    </Description>
    <Cards>
      <Card>
        <CardValue>
          <strong>{info.people_subscribed_number}</strong>
        </CardValue>
        <CardDescription>palestrantes entre escolas, alunos e investidores.</CardDescription>
      </Card>
      <Card>
        <CardValue>
          +<strong>{info.content_hour}</strong>h
        </CardValue>
        <CardDescription>de conteúdo e trocas sobre o modelo ISA no Brasil.</CardDescription>
      </Card>
      <Card>
        <CardValue>
          +<strong>{info.people_number}</strong>
        </CardValue>
        <CardDescription>pessoas inscritas nos 6 dias do evento.</CardDescription>
      </Card>
    </Cards>
  </InfoContainer>
)

export default Infos
