import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const PartnersContainer = styled.div`
  padding: 24px;
`

export const PartnersContent = styled.div`
  @media (min-width: 1120px) {
    margin: 0 auto;
    max-width: 1064px;
    padding: 72px 24px;

    display: flex;
    justify-content: space-between;
    gap: 24px;
  }
`

export const PartnersTitle = styled.h2`
  font-weight: 500;
  font-size: 22.81px;
  line-height: 36px;

  letter-spacing: 0.005em;
  margin-bottom: 48px;

  color: ${theme.colors.bluePrimary};

  ${theme.breakpoints.up('md')} {
    font-size: 31.25px;
    line-height: 40px;
    max-width: 100%;
  }
`

export const PartnersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 48px 24px;

  @media (min-width: 700px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 56px 72px;
    width: 100%;
  }
`

export const PartnerLogo = styled.img`
  max-width: 100%;
  height: 48px;
  align-self: center;
  justify-self: center;
  object-fit: contain;

  @media (min-width: 1120px) {
    max-width: 175px;
    height: auto;
  }
`
