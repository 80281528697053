import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  max-width: 288px;
  border-radius: 16px;

  :nth-child(1) {
    margin-bottom: 71px;
  }

  @media (min-width: 670px) {
    :nth-child(1) {
      margin-right: 24px;
      margin-bottom: 0;
    }
  }
`

export const Img = styled.img`
  width: 148px;
  height: 108px;
  object-fit: cover;

  @media (min-width: 670px) {
    width: 100%;
    height: 228px;
    border-radius: 16px;
  }
`

export const Title = styled.h3`
  font-weight: 500;
  font-size: 22.81px;
  line-height: 36px;
  color: ${theme.colors.bluePrimary};
  margin: 13px 0;

  @media (min-width: 670px) {
    font-size: 25px;
    line-height: 32px;
    letter-spacing: 0.0025em;
    margin: 23px 0 15px;
  }
`

export const Text = styled.h3`
  font-weight: 500;
  font-size: 15.84px;
  line-height: 24px;
  max-width: 312px;
  margin-bottom: 19px;

  @media (min-width: 670px) {
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.0075em;
    margin-bottom: 24px;
  }
`

export const Link = styled.a`
  display: block;
  font-weight: 600;
  text-decoration: none;
  color: ${theme.colors.bluePrimary};
  display: flex;
  align-items: center;

  svg {
    path {
      stroke: ${theme.colors.bluePrimary};
    }
  }

  @media (min-width: 670px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
  }
`

export const TextLink = styled.span`
  margin-right: 9px;
`
