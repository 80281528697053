import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IContainer {
  backgroundColor: string
}

interface IIndicator {
  isActive: string
}

interface IIndicatorBox {
  isVariant?: boolean
}

type IContent = IIndicatorBox

export const TestimonialsContainer = styled.section<IContainer>`
  width: 100%;
  background: ${({ backgroundColor }) => backgroundColor};
`
export const DesktopCarousel = styled.ul`
  > li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`

export const DesktopCarouselWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Content = styled.div<IContent>`
  max-width: 1280px;
  height: ${({ isVariant }) => (isVariant ? '456px' : '536px')};
  display: flex;
  position: relative;
  overflow: hidden;
  margin: 0 auto;

  @media (max-width: 750px) {
    flex-direction: column-reverse;
    height: 100%;
    padding: 38px 24px 20px;
  }
`

export const IndicatorBox = styled.div<IIndicatorBox>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 250px;
  margin-left: ${({ isVariant }) => (isVariant ? '43%' : '0')};

  @media (max-width: 750px) {
    top: ${({ isVariant }) => (isVariant ? '300px' : '250px')};
    width: calc(100% - 72px);
    margin-left: unset;
  }

  button {
    border: none;
    background-color: transparent;
  }
`

export const IndicatorContent = styled.div`
  margin: 0 25px;
  display: flex;
`

export const Indicator = styled.div<IIndicator>`
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${theme.colors.white100};

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${theme.colors.bluePrimaryDark};

      a {
        visibility: visible;
      }
    `}
`

export const ControlButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;

  &:first-child img {
    transform: scaleX(-1);
  }

  img {
    pointer-events: none;

    path {
      stroke: black;
    }
  }
`

export const DotsWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: -200px;
  margin-left: 8%;
  display: block;
  width: 100%;
`
