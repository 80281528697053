import React, { useRef } from 'react'
import Carousel from 'react-multi-carousel'
import { ArrowLeft, ArrowRight } from '~/assets/svg'
import { IButtonGroup, SkipCallbackOptions } from 'types'
import {
  CarouselStoryContainer,
  StoryContent,
  CarouselContent,
  StorySection,
  StoryText,
  BigNumber,
  IndicatorBox,
  IndicatorContent,
  ControlButton,
  Indicator,
} from './CarouselStory.styles'

interface IStory {
  text: JSX.Element | string
  year: string
}

interface ICarouselStory {
  content: IStory[]
}

const CarouselStory = ({ content = [] }: ICarouselStory) => {
  const carousel = useRef<Carousel>({} as Carousel)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  const items = content.map((contentItem: IStory, index: number) => (
    <StoryContent key={index.toString()}>
      <StorySection>
        <BigNumber>{contentItem.year}</BigNumber>
      </StorySection>
      <StorySection>
        <StoryText className="story-text">{contentItem.text}</StoryText>
      </StorySection>
    </StoryContent>
  ))

  const getAllDots = (currentSlide: number, goToSlide: (slide: number, skipCallbacks?: SkipCallbackOptions) => void) =>
    items.map((_content, index) => (
      <Indicator
        key={index.toString()}
        isActive={currentSlide === index || currentSlide === index - content.length || currentSlide === index + content.length}
        onClick={() => goToSlide(index + 2)}
      />
    ))

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }: IButtonGroup) => {
    const {
      carouselState: { currentSlide },
    } = rest
    return (
      <IndicatorBox>
        <ControlButton type="button" onClick={() => previous(1)}>
          <ArrowRight color="#FFF" />
        </ControlButton>
        <IndicatorContent> {getAllDots(currentSlide - 2, goToSlide)} </IndicatorContent>
        <ControlButton type="button" onClick={() => next(1)}>
          <ArrowLeft color="#FFF" />
        </ControlButton>
      </IndicatorBox>
    )
  }

  const carouselStory = (
    <div>
      {!!carousel.current && (
        <Carousel
          autoPlay
          pauseOnHover
          autoPlaySpeed={6000}
          ref={carousel}
          swipeable
          draggable
          showDots={false}
          arrows={false}
          responsive={responsive}
          ssr
          infinite
          transitionDuration={500}
          containerClass="carousel-container"
          customButtonGroup={
            <ButtonGroup
              next={carousel.current.next}
              previous={carousel.current.previous}
              goToSlide={carousel.current.goToSlide}
              carouselState={{ currentSlide: 2 }}
            />
          }
          renderButtonGroupOutside
        >
          {items}
        </Carousel>
      )}
    </div>
  )

  return (
    <CarouselStoryContainer>
      <CarouselContent>
        <StoryContent>
          <StorySection>
            <StoryText>Nascemos em 2018 como consequência da vontade de empreender e do desejo de fazer a diferença.</StoryText>
            <StoryText>
              Fernando Franco e Mario Perino uniram seus conhecimentos sobre o mercado financeiro à bagagem técnica de Luciano
              Krebs, e juntos definiram nosso objetivo: transformar o cenário educacional brasileiro.
            </StoryText>
          </StorySection>
          <StorySection />
        </StoryContent>

        {carouselStory}
      </CarouselContent>
    </CarouselStoryContainer>
  )
}

export default CarouselStory
