import NextLink from 'next/link'
import { ICollapseSection } from 'types'
import { CollapseGroup } from '../CollapseGroup'
import { CollapseWrapper, CollapseContent, TitleFaq, FAQContent, Span, Button } from './Faq.styles'

interface IFaq {
  title?: string
  questions: ICollapseSection[]
  variant?: boolean
}

const Faq = ({ title, questions, variant = false }: IFaq) => (
  <CollapseWrapper variant={variant}>
    <CollapseContent>
      <TitleFaq>
        {title || (
          <>
            Dúvidas? <br /> A gente te ajuda
          </>
        )}
      </TitleFaq>
      <FAQContent>
        <CollapseGroup items={questions} variant={variant} />
        <Span>Tem outras dúvidas? Acesse nossa página de perguntas e respostas.</Span>
        <NextLink href="/duvidas" passHref legacyBehavior>
          <Button
            text="Acessar página"
            width="167px"
            mobileWidth="143px"
            buttonProps={{
              href: '/duvidas',
            }}
          />
        </NextLink>
      </FAQContent>
    </CollapseContent>
  </CollapseWrapper>
)

export default Faq
