import styled from 'styled-components'
import { theme } from '@provi/provi-components'

interface IContainerProp {
  backgroundColor: string
}

export const Container = styled.section<IContainerProp>`
  background: ${({ backgroundColor }) => backgroundColor && theme.colors.bluePrimaryLight};
  padding: 50px 0;
`

export const PublicationsWrapper = styled.div`
  ${theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: repeat(4, minmax(auto, 1fr));
    gap: 24px;
    max-width: 1280px;
    position: relative;
    margin-left: calc((100% - 1064px) / 2);
    max-width: 1164px;
    padding-left: 24px;

    @media (max-width: 1064px) {
      margin-left: auto;
    }
  }
`

export const CarouselWrapper = styled.div`
  width: calc(100% - 210px);
  position: relative;

  ${theme.breakpoints.down('md')} {
    width: 100%;

    li {
      display: flex;
      justify-content: center;
    }
  }
`

export const TitleContainer = styled.div`
  margin: 0 24px;
  display: flex;
  flex-direction: column;
  width: 90%;

  > h2 {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;
    letter-spacing: 0.0025em;
  }

  span {
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0075em;
  }

  > a {
    width: 184px;
    margin: 0 0 30px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: ${theme.colors.bluePrimaryDark};
    font-weight: 600;
    font-size: 13.2px;
    line-height: 20px;

    svg {
      margin-left: 8px;
    }
  }

  ${theme.breakpoints.up('md')} {
    margin: 0 24px 0 0;
    width: 184px;

    > a {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;
    }
  }
`

export const ContainerCards = styled.div`
  display: flex;
  align-items: center;
`

export const PublicationText = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
`

export const PublicationTitle = styled.h2`
  color: ${theme.colors.black};
  font-weight: 500;
  margin-bottom: 30px;

  ${theme.breakpoints.up('md')} {
    position: relative;
    margin-bottom: 34px;
    font-size: 25px;
    line-height: 32px;
    letter-spacing: 0.0025em;
  }
`

export const ContainerInputs = styled.div`
  display: flex;
  margin: 30px auto;

  input {
    width: 12px;
    height: 12px;
    margin: 0 12px;
  }
`

export const IndicatorBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 24px 10px;

  button {
    border: none;
    background-color: transparent;
  }

  ${theme.breakpoints.up('md')} {
    visibility: hidden;
    position: absolute;
  }
  ${theme.breakpoints.down('lg')} {
    visibility: visible;
    bottom: 0;
  }
`

export const IndicatorContent = styled.div`
  margin: 0 66px;
  display: flex;

  ${theme.breakpoints.up('md')} {
    margin: 0 25px;
  }
`

export const Indicator = styled.div`
  width: 12px;
  height: 12px;
  margin: 0 12px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${theme.colors.white100};

  &.active {
    background-color: ${theme.colors.bluePrimaryDark};

    a {
      visibility: visible;
    }
  }
`

export const ControlButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
`

export const Icon = styled.img`
  pointer-events: none;

  path {
    stroke: black;
  }
`

export const MobileCarouselContainer = styled.div`
  ${theme.breakpoints.up('md')} {
    display: none;
  }
`

export const DesktopCarouselContainer = styled.div`
  display: flex;
  gap: 24px;

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`
