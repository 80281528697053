import { Container } from './styles'

import Card from './Card'

export const HighlightCards = () => (
  <Container>
    <Card
      iconPath="/assets/svg/dollar.svg"
      title="Renda mínima"
      description="ao alcançar a renda mínima, o estudante passa a pagar um percentual mensal pré-estabelecido."
      alt="Símbolo cifrão."
    />
    <Card
      iconPath="/assets/svg/percent.svg"
      title="Porcentagem de compartilhamento"
      description="é o quanto o estudante paga a partir do momento que atingir a renda mínima."
      alt="Símbolo de porcentagem."
    />
  </Container>
)
