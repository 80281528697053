import { useEffect, useState } from 'react'
import { Container, Row, Content, Collapse, IconButton, AddIcon, FeaturedTitle, QuestionTitle } from './CollapseSection.style'
import { ICollapseSection } from 'types'
import sanitizeHtml from 'sanitize-html'

const CollapseSection = ({
  title = '',
  content = '',
  width = '',
  isLast = false,
  iconColor = '#000000',
  titleColor = '#000000',
  active = false,
  onChange,
  variant = false,
  featuredTitle = false,
}: ICollapseSection) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  useEffect(() => {
    if (onChange && open) {
      onChange()
    }
  }, [open])

  useEffect(() => {
    setOpen(active)
  }, [active])

  const renderContent =
    typeof content === 'string' ? (
      <Content
        aria-label="conteúdo"
        className="collapse-content"
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content),
        }}
      />
    ) : (
      <Content aria-label="conteúdo" className="collapse-content">
        {content}
      </Content>
    )

  return (
    <Container containerWidth={width} isLast={isLast} variant={variant}>
      <Row onClick={handleClick} aria-label={title} titleColor={titleColor} className="collapse-row">
        {featuredTitle ? <FeaturedTitle>{title}</FeaturedTitle> : <QuestionTitle>{title}</QuestionTitle>}
        <IconButton aria-label={`expandir o conteúdo ${title}`} iconColor={iconColor}>
          <AddIcon
            aria-label={open ? 'Ícone fechar' : 'Ícone expandir'}
            titleAccess={open ? 'Ícone fechar' : 'Ícone expandir'}
            style={{ transform: open ? 'rotate(45deg)' : 'rotate(0deg)', transition: 'all 0.192s ease' }}
          />
        </IconButton>
      </Row>
      <Collapse in={open} timeout="auto" style={{ width: '100%' }}>
        {renderContent}
      </Collapse>
    </Container>
  )
}

export default CollapseSection
