import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 24px;

  @media (min-width: 650px) {
    grid-template-columns: 1fr 1fr;
    padding-top: 16px;

    > div {
      padding: 16px 16px 15px;

      :first-child {
        margin-bottom: 50%;
      }

      :last-child {
        margin-top: 50%;
      }

      h3 {
        margin-top: 11px;
      }

      div:nth-child(3) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 11px;
      }
    }
  }
`
