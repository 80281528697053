import React from 'react'
import { ArticleCardContainer, Svg, ArticleCardTitle, ArticleCardText, Link, Icon, CardWrapper } from './styles'

export interface IArticleCardProps {
  image: {
    url: string
  }
  title: string
  text: string
  link: string
  isActive: boolean
}

const ArticleCard = ({ image, title = '', text = '', link = '', isActive }: IArticleCardProps) => (
  <ArticleCardContainer isActive={isActive}>
    <CardWrapper>
      <Svg src={image.url} alt={title} isActive={isActive} />

      <ArticleCardTitle>{title}</ArticleCardTitle>

      <ArticleCardText>{text}</ArticleCardText>
    </CardWrapper>

    <Link href={link} target="_blank">
      Ler artigo
      <Icon src="/assets/svg/arrow-up.svg" alt="Ilustração de uma seta diagonal apontando para a direita e para cima." />
    </Link>
  </ArticleCardContainer>
)

export default ArticleCard
