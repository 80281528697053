import { useCallback } from 'react'
import { FAQNavigatorWrapper, FAQNavigatorList, FAQNavigatorItem, FAQNavifatorLinkText, A } from './FAQNavigator.style'
import { Link } from 'react-scroll'
import { InputFieldV3 as Input, Breadcrumbs } from '@provi/provi-components'
import { useWindowSize } from '~/hooks/useWindowSize'
import { IBreadcrumb } from 'types'
import { SearchIcon } from '~/assets/svg/searchIcon'
import theme from '~/styles/theme'
import NextLink from 'next/link'

interface ISection {
  anchor: string
  label: string
}

interface IFAQNavigator {
  menuItems: ISection[]
  onSearch?: (value: string) => void
  marginTop: number
}

const breadcrumbsProps: IBreadcrumb = {
  levels: [
    <NextLink href="/" passHref legacyBehavior>
      <A>Início</A>
    </NextLink>,
    'A Provi',
    'Perguntas frequentes',
  ],
  currentColor: 'white',
  color: 'white',
}

const FAQNavigator = ({ menuItems, onSearch, marginTop }: IFAQNavigator) => {
  const { width } = useWindowSize()

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (onSearch) {
      onSearch(event.target.value)
    }
  }, [])

  return (
    <FAQNavigatorWrapper marginTop={marginTop}>
      {breadcrumbsProps && !!width && width <= 750 && (
        <Breadcrumbs
          levels={breadcrumbsProps.levels}
          color={breadcrumbsProps?.color || '#000000'}
          currentColor={breadcrumbsProps?.currentColor || theme.provi.colors.bluePrimaryDark}
        />
      )}

      <Input
        icon={<SearchIcon />}
        data-testid="search-input"
        placeholder="Busque respostas"
        width="100%"
        onChange={handleChange}
      />
      {!!width && width > 750 && (
        <FAQNavigatorList>
          {[menuItems[2], menuItems[4], menuItems[3], menuItems[1], menuItems[0]].map((item) => (
            <FAQNavigatorItem key={item.anchor}>
              <Link to={item.anchor} smooth offset={-72}>
                <FAQNavifatorLinkText>{item.label}</FAQNavifatorLinkText>
              </Link>
            </FAQNavigatorItem>
          ))}
        </FAQNavigatorList>
      )}
    </FAQNavigatorWrapper>
  )
}

export default FAQNavigator
